<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-3">
                    <v-card-title>
                        <h2>TTP Trend Detection Correlation report: {{correlationdate}}</h2>
                    </v-card-title>
                    <v-card-text>
                        <p>
                            This page displayes analysis of honeypot
                            command-line data and identified recent
                            correlations
                            (or decorrelations) of attacker <strong>Tools Techniques and
                            Practices</strong>.  This report identifies leading
                            indicators of hacking techniques by observing
                            changes in penetration scripts and
                            root-kits over time.
                        </p>
                    </v-card-text>
                </v-card>

                <v-card class="mb-3">
                    <v-card-title>Dataset selection</v-card-title>
                    <v-card-text>
                        <v-select label="Date"
                                  v-model="correlationdate"
                                  :items="date_list">
                        </v-select>
                    </v-card-text>
                </v-card>

                <v-card class="mb-5">
                    <v-card-title>Correlation Summary</v-card-title>
                    <v-card-text>
                        <v-simple-table>
                            <tr><th class="text-left"></th>
                                <th class="text-left">Command 1</th>
                                <th class="text-left">Command 2</th>
                                <th class="text-left">Correlation Strength</th>
                                <th class="text-left">Length<br />(days)</th>
                                <th class="text-left">&nbsp;</th>
                            </tr>
                            <tr v-for="corr in correlations" :key="corr.id">
                                <td>
                                    <v-btn
                                        x-small
                                        class="mr-3 ml-0 px-0"
                                        @click="ourGoto(corr);">
                                        <v-icon>
                                            mdi-arrow-right-bold
                                        </v-icon>
                                    </v-btn>
                                <td>{{corr.key1}}</td>
                                <td>{{corr.key2}}</td>
                                <td>{{corr.min_kendall_rank}}</td>
                                <td>{{corr.corr_len}}</td>
                                <td>
                                    <v-chip
                                        v-if="corr.is_new"
                                        x-small
                                        color="red"
                                        text-color="white">NEW</v-chip>
                                </td>
                            </tr>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
                <v-card
                    class="mb-4"
                    color="#f4f6ff"
                    v-for="corr in correlations"
                    :id="`x${corr.hash_id}`"
                    :key="corr.id">
                    <v-card-title>
                        {{corr.key1}} vs {{corr.key2}}
                            <v-spacer/>
                            <v-chip
                                v-if="corr.is_new"
                                class="float-right"
                                color="red"
                                text-color="white">NEW</v-chip>
                    </v-card-title>
                    <v-card-text style="background-color: #ffffff;" class="corrsection">
                        <h3>Detection details</h3>
                        <v-simple-table dense>
                            <tbody>
                                <tr><th width="20%">Dates</th>
                                    <td width="25%">{{corr.corr_start_date}} - {{corr.corr_stop_date}}</td>
                                    <td width="55%">({{corr.corr_len}} days)</td></tr>
                                <tr><th>Correlation Score</th>
                                    <td>{{corr.min_kendall_rank}}</td>
                                    <td v-if="corr.corr_type == 'neg'">Negative (window size: {{corr.window_size}})</td>
                                    <td v-if="corr.corr_type == 'pos'">Positive (window size: {{corr.window_size}})</td>
                                </tr>
                                <tr><th>Command 1</th><td colspan="2">{{corr.key1}}</td></tr>
                                <tr><th>Command 2</th><td colspan="2">{{corr.key2}}</td></tr>
                                <tr><th>Example command set</th><td colspan="2">{{corr.key_full}}</td></tr>
                            </tbody>
                        </v-simple-table>

                        <h3 class="mt-5 mb-3">Command count graph &amp; correlation value</h3>
                        <v-img :src="corr.graph_file" v-if="corr.graph_file" contain max-width="80%" />
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import { fetch_one, get_recent_date, get_last_thirty_days, round_rows, get_day_before } from "../utils.js";
 import { get_url_value, set_url_value } from '../utils.js'
 import goTo from 'vuetify/lib/services/goto';

 var correlation_data = {
     'correlationdate': "recent",
     'correlations': [],
 }

 var fetch_correlations_dropdown = async function() {
     console.log("correlation date change" + correlation_data['correlationdate'])
     if (correlation_data['correlationdate'] === 'recent') {
         correlation_data['correlationdate'] = get_recent_date(3);
     }
     var data = await fetch_correlations_for_date(correlation_data['correlationdate']);

         var yesterday = get_day_before(correlation_data['correlationdate']);
     var yesterdata = await fetch_correlations_for_date(yesterday);


     data.forEach(function(item) {
         item['is_new'] = true;
         yesterdata.forEach(function(yesteritem) {
             if (item['key1'] === yesteritem['key1'] && item['key2'] === yesteritem['key2']) {
                 item['is_new'] = false;
             }
         });
     });

     correlation_data['correlations'] = data;
 };

 var fetch_correlations_for_date = async function(ourdate) {
     try {
         var data = await fetch_one("/data/correlations/" + ourdate + ".json");

         data['correlations'].forEach(function(item) {
             item['graph_file'] = "/images/correlations/" +
                                  ourdate + "/" +
                                  item['hash_id'] + ".png";
         });

         // round some variables
         round_rows(data['correlations'], ['min_kendall_rank']);
         return data['correlations'];
     } catch (error) {
         console.log("failed to load correlations")
         console.log(error)
     }
 }

 var ourGoto = function(where) {
     console.log(where);
     goTo("#x" + where.hash_id);
 }

 export default {
     name: 'CorrelationReport',
     data: function() { return correlation_data; },
     props: ['correlation_data'],
     created() {
         correlation_data['correlationdate'] =
             get_url_value(this, 'date', 'recent');
         fetch_correlations_dropdown(this);
     },
     watch: {
         "correlationdate": function() {
             set_url_value(this, 'date', correlation_data['correlationdate']);
             fetch_correlations_dropdown(this);
         },
     },
     computed: {
         date_list: get_last_thirty_days,
     },
     methods: {
         'ourGoto': ourGoto,
     },
 }

</script>

<style>
    .corrsection {
        background-color: "#ffffff";
    }
</style>
