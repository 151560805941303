<template>
    <v-app-bar>
        <v-toolbar-items>
            <router-link to="/GiFT">
                <v-btn small color="primary" class="mx-3" width="15em">GiFT Threatfeed</v-btn>
            </router-link>
            <router-link to="/dailybriefing">
                <v-btn small color="primary" class="mx-3" width="15em">
                    Global Threat Briefing
                    <v-badge
                        v-if="briefing_counters > 0"
                        :content="briefing_counters"
                        :value="briefing_counters"
                        class="mb-2"
                        color="red"
                    ></v-badge>
                </v-btn>
            </router-link>
            <router-link to="/Malware">
                <v-btn small color="primary" class="mx-3" width="15em">Malware Analysis</v-btn>
            </router-link>
            <!--
            <router-link to="/explore">
                <v-btn small color="primary" class="mx-3" width="15em">TTP Templates</v-btn>
            </router-link>
            -->
            <router-link to="/analysis">
                <v-btn small color="primary" class="mx-3" width="15em">Analysis</v-btn>
            </router-link>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
 var menu_data = {
     'briefing_counters': 0, // XXX: need to redo this badge
 };

 export default {
     name: 'NavMenu',
     data: function() { return menu_data; },
     props: ['user'],
 };
</script>

<style scoped>
 .router-link-exact-active button,
 .router-link-active button {
     color: black !important;
     background-color: #8ac5ff !important;
 }
 a {
     text-decoration: none;
 }
.v-toolbar__items {
    margin: auto;
 }
</style>
