<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-2">
                    <v-card-title>Daily Threat Feed Content Counts</v-card-title>
                    <v-card-text>
                        <v-expansion-panels>
                            <v-expansion-panel
                                id="all" key="all">
                                <v-expansion-panel-header>
                                    Total threats in the last 24h: <b>{{total_count}}</b>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-img contain max-width="80%" src="/stats/gift-graphs/all.png">
                                    </v-img>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
                <v-card class="mb-2">
                    <v-card-title>Statistics for {{date}}</v-card-title>
                    <v-card-text>
                        <v-data-table dense
                                      :items="tags_table"
                                      :headers="stats_headers"
                                      :hide-default-footer="true"
                                      sort-by="total"
                                      sort-desc="false"
                                      disable-pagination>
                        </v-data-table>
                    </v-card-text>
                </v-card>
                <v-card>
                    <v-card-title>GiFT trend graphs</v-card-title>
                    <v-card-text>
                        <v-expansion-panels>
                            <v-expansion-panel
                                v-for="(tagdata, index) in tags"
                                :key="index"
                                :value="index"
                                :id="index">
                                <v-expansion-panel-header>
                                    Recent {{index}}: total = {{tagdata['total']}}, unique = {{tagdata['unique']}}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <a :href="`https://gift.ant.isi.edu/stats/gift-graph/${index}.png`">
                                        <v-img contain max-width="80%" :src="`https://gift.ant.isi.edu/stats/gift-graphs/${index}.png`" />
                                    </a>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 var gift_stats = {"date": "2021-04-12",
                   "tags": {},
                   "tags_table": [{'total': 0, 'unique': 0, 'tag': 'loading'}],
                   "total_count": 2913579,
                   'stats_headers': [
                       {'text': 'Tag',         'value': 'tag', sortable: true},
                       {'text': 'Total Count', 'value': 'total', sortable: true},
                       {'text': 'Unique Count', 'value': 'unique', sortable: true},
 ]};

 export default {
     name: 'GiftStats',
     data: function() { return gift_stats; },
     props: [],
     created() { fetch_stats() },
 }

 var fetch_stats = async function() {
     try {
         var url = "/data/gift-stats.json"
         const response =
             await fetch(url);
         const data = await response.json();

         gift_stats['date'] = data['date']
         gift_stats['tags'] = data['tags']
         gift_stats['total_count'] = data['total_count']
         console.log("loaded stats")

         gift_stats['tags_table'] = []
         for (let [key, value] of Object.entries(gift_stats['tags'])) {
             value['tag'] = key;
             gift_stats['tags_table'].push(value)
         }
     } catch (error) {
         console.log("failed to load stats")
     }
 }

</script>
