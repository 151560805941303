<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <h3>GiFT Content Descriptions</h3>
                <p>The GiFT contains multilpe sub-components, with
                    different datatypes and kafka topic streams.  The
                    following tables describe each of these separate
                    feeds, where the data comes from and which topics they
                    belong to.</p>
                <p>In general, the aggregate feed is designed such that individual feed items tagged with a <em>tag</em> below contain a <em>base priority</em> for the reliability of the feed source in general.  A <em>priority &gt; 5</em> is worth potentially filtering on as it should have a low degree of false positives.  Note that many feed items themselves have a <em>priority_adj</em> field that should be added to the base priority when processing, which may raise or lower the final score from the base priority of an individual feed.  See the YAML file for further formatting details.</p>
                <p>This information is also available in <a href="/feed-descriptions.yml">YAML</a> and <a href="/data/descriptions.json">JSON</a> formats.</p>
                <v-card class="mb-2" v-for="(feed, index) in feeds" :key="`descr-${index}`">
                    <v-card-title>{{feed.name}}</v-card-title>
                    <v-card-text>
                        <v-simple-table dense>
                            <tbody>
                                <tr><th>Tag:</th><td>{{feed.tag}}</td></tr>
                                <tr><th>Priority:</th><td>{{feed.base_priority}}</td></tr>
                                <tr><th>Data type:</th><td>{{feed.data_type}}</td></tr>
                                <tr><th>Kafka topic:</th><td>{{feed.feed}}</td></tr>
                                <tr><th>Update Frequency:</th><td>{{feed.update_frequency}}</td></tr>
                                <tr><th>Description:</th><td><p>{{feed.description}}</p></td></tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 var gift_descriptions = {"feeds": []}

 export default {
     name: 'Descriptions',
     data: function() { return gift_descriptions; },
     props: [],
     created() {
         fetch_descriptions()
     }
 }

 var fetch_descriptions = async function() {
     try {
         var url = "/feed-descriptions.json"
         const response =
             await fetch(url);
         const data = await response.json();

         gift_descriptions['feeds'] = data['feeds']
         console.log("loaded feeds")

     } catch (error) {
         console.log("failed to load feeds")
     }
 }

</script>
