<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <p v-if="!dataloading">The following tables represent statistics gathered from
                    <b>{{date}}</b>.

                </p>
                <p class="mt-3"><b>Trend description:</b> {{currenttrend.description}}</p>

                <v-card class="mb-3">
                    <v-card-title>Dataset selection</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="4">
                                <v-select label="Dataset"
                                          v-model="whattrend"
                                          :items="datasets">
                                </v-select>
                            </v-col>
                            <v-col cols="4">
                                <v-select label="Date"
                                          v-model="date"
                                          :items="date_list">
                                </v-select>
                            </v-col>
                            <!-- 
                            <v-col cols="4">
                                <v-select label="Compare Site"
                                          v-model="site"
                                          :items="site_list">
                                </v-select>
                            </v-col>
                            -->
                        </v-row>
                    </v-card-text>
                </v-card>

                <span id="graph">
                    <v-alert
                        v-if="loading_chart"
                        color="yellow">
                        Loading graph for {{loading_value}}...
                    </v-alert>
                    <v-card class="mb-3">
                        <v-card-title>
                            Timeline of malicious activity to {{currenttrend.title}}
                        </v-card-title>
                        <v-card-text>
                            <span  v-if="chartdata_raw.length > 0">
                            <line-chart
                                adapter="highcharts"
                                :data="chartdata_raw"
                                v-if="!percentage"
                                ytitle="Raw count of malicious traffic"
                                :curve="false"
                            >
                            </line-chart>
                            <line-chart
                                adapter="highcharts"
                                :data="chartdata_percent"
                                v-if="percentage"
                                ytitle="Fraction of malicious traffic"
                                :curve="false"
                            >
                            </line-chart>
                            <v-row>
                                <v-col cols="2">
                                    <v-checkbox 
                                        hide-details
                                        class="mt-0 mb-0 mr-0 ml-0 shrink"
                                        v-model="percentage"
                                        label="Show fractions">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                    <v-checkbox 
                                        hide-details
                                        class="mt-0 mb-0 mr-0 ml-0 shrink"
                                        v-model="goto_graph"
                                        label="Scroll to graph">
                                    </v-checkbox>
                                </v-col>
                                <v-col cols="2">
                                    <v-select label="Days"
                                              v-model="graph_length"
                                              :items="graph_length_options">
                                    </v-select>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn color="primary"
                                           @click="reset_checked_values()"
                                           class="mt-3"
                                           x-small>Reset</v-btn>

                                </v-col>
                            </v-row>
                            </span>
                            <v-row>
                                <v-col cols="2">
                                    <v-btn color="primary"
                                           @click="mark_novel()"
                                           class="mt-3"
                                           x-small>Graph Novel</v-btn>
                                    <br />
                                </v-col>
                                <v-col cols="2">
                                    <v-btn color="primary"
                                           @click="mark_new()"
                                           class="mt-3"
                                           x-small>Graph New</v-btn>
                                    <br />
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        width="50%"
                                        v-model="manual_graph"
                                        hint="Manually enter values to graph here"
                                        :label="`manual graph a ${currenttrend.title} value`">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="2">
                                    <v-btn color="primary"
                                           @click="graph_it()"
                                           class="mt-3"
                                           x-small>Graph It</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </span>

                <v-alert v-if="dataloading" color="yellow">
                    Loading data for {{date}}...
                </v-alert>
                <v-alert v-if="dataloaderror" color="red">
                    Failed loading data for {{date}}...
                </v-alert>

                <v-tabs v-model="tabletab" class="justify-center">
                    <v-tab class="mb-3" v-if="!dataloading && !dataloaderror"
                           href="#top" key="top">
                        Top malicious acitivity
                        <v-badge
                            v-if="api2[whattrend]['top_new'] > 0"
                            color="red"
                            class="mb-2"
                            :content="api2[whattrend]['top_new']">
                        </v-badge>
                    </v-tab>
                    <v-tab class="mb-3" v-if="!dataloading && !dataloaderror"
                           href="#trend" key="trend">
                        Top trending malicious activity
                        <v-badge
                            color="red"
                            class="mb-2"
                            v-if="api2[whattrend]['trend_new'] > 0"
                            :content="api2[whattrend]['trend_new']">
                        </v-badge>
                    </v-tab>
                    <v-tab class="mb-3" v-if="!dataloading && !dataloaderror"
                           href="#sitecount" key="sitecount">
                        Contributing sites
                    </v-tab>
                    <!-- 
                         <v-tab class="mb-3" v-if="!dataloading && !dataloaderror && 'site' in api2[trend_identifier_in] && api2[trend_identifier_in]['site'].length > 0" href="#sitecomparison"  key="sitecomparison">
                         Site comparison: {{comparison['message']['compared_to']}}
                         </v-tab>
                    -->
                    <v-tab-item value="sitecomparison" :eager="eager">
                        <TrendTable
                            v-if="whattrend in api2"
                            trend_type="site"
                            :date="date"
                            :trend_table="api2[whattrend]['site']"
                            :trend_title="api2[whattrend]['title']"
                            :trend_identifier="whattrend"
                            :rows_checked="trend_rows_checked"
                            @emit_clicks="receive_values">
                        </TrendTable>
                    </v-tab-item>

                    <v-tab-item value="trend" :eager="eager">

                        <p>The following table shows the top upward
                            trending service connections from known
                            malicious addresses.</p>
                        <TrendTable
                            v-if="whattrend in api2"
                            trend_type="trend"
                            :date="date"
                            :trend_table="api2[whattrend]['trend']"
                            :trend_title="api2[whattrend]['title']"
                            :trend_identifier="whattrend"
                            :rows_checked="trend_rows_checked"
                            @emit_clicks="receive_values">
                        </TrendTable>
                    </v-tab-item>

                    <v-tab-item value="top">
                        <p>The following table shows the top percentages
                            of service connections from known
                            malicious addresses.</p>

                        <TrendTable
                            v-if="whattrend in api2"
                            trend_type="top"
                            :date="date"
                            :trend_table="api2[whattrend]['top']"
                            :trend_title="api2[whattrend]['title']"
                            :trend_identifier="whattrend"
                            :rows_checked="trend_rows_checked"
                            @emit_clicks="receive_values">
                        </TrendTable>
                    </v-tab-item>

                    <v-tab-item value="sitecount">
                        <p>The following table shows the number of
                        sites contributing data.</p>

                        <v-simple-table>
                            <tr><th>Date</th><th>Count</th></tr>
                            <tr v-for="row in api2[whattrend]['sitecount']"
                                :key="row[0]">
                                <td>{{row[2]}}</td><td>{{row[1]}}</td>
                            </tr>
                        </v-simple-table>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-chip
                    x-small
                    color="red"
                    text-color="white">NEW</v-chip>:
                New in the last 7 days to this table
            </v-col>
            <v-col cols="6">
                <v-chip
                    x-small
                    color="blue"
                    text-color="white">NOVEL</v-chip>:
                Rarely seen or very new values
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import { fetch_one, round_dict, round_rows, percent_rows } from "../utils.js";
 import dateformat from 'dateformat';
 import goTo from 'vuetify/lib/services/goto';
 import { get_url_value, set_url_value } from '../utils.js'

 import Vue from 'vue';
 import Chartkick from 'vue-chartkick';
 import Highcharts from 'highcharts';

 import TrendTable from '../components/TrendTable';

 Vue.use(Chartkick.use(Highcharts))

 var service_data = {
     'services': {'message': {'all': {'total_sites': 0}}},
     'comparison': {'message': {'compared_to': 'none', 'top_diff': []}},
     'date': "recent",
     'value_data': {},
     'chartdata': [],
     'chartdata_raw': [],
     'chartdata_percent': [],
     'tmp': {},
     'loading_value': "",
     'loading_chart': false,
     'dataloading': true,
     'dataloaderror': false,
     'site': "All",
     'filtered_diffs': [],
     'filtered_percents': [],
     'tabletab': 'top',
     'date_map': {},
     'api2': {
         '': {
         }
     },
     'whattrend': undefined,
     'trend_rows_checked': [],
     'currenttrend': {},
     'compare_offset': 7,
     'total_count': 0,
     'percentage': true,
     'eager': false,
     'graph_keys': {},
     'graph_keys_str': "",
     'manual_graph': "",
     'goto_graph': true,
     'graph_length': 180,
     'graph_length_options': [30, 60, 90, 180, 360],
     //     'date_list': ['recent',1,2,3],
     site_list: ['All', 'vt', 'uva'],
     trend_list: ['trend', 'top'],
     datasets: [],
 }

 var filter_data = function() {
     try {
         round_dict(service_data['services']['message']['all'],
                    ['percent_right', 'diff']);

     } catch (error) {
         console.log("failed rounding");
         console.log(error);
     }
     try {
         service_data['dataloading'] = false;
     } catch (error) {
         console.log("couldn't filter rows");
         console.log(error);
     }
 }

 var fetch_datatypes = async function(filter_list) {

     await fetch_one("/api/2/datatypes",
                     'datatypes', service_data);
     console.log("loaded dadatypets")
     console.log(service_data['datatypes'])

     var replacement_list = {}

     var message = service_data['datatypes']['message']
     var datasets = []
     for (var item in message) {
         if (item &&
             (!filter_list ||
              filter_list.length === 0 ||
              filter_list.includes(message[item][1]))) {
             
             replacement_list[message[item][1]] = {
                 'numid': message[item][0],
                 'id': message[item][1],
                 'title': message[item][2],
                 'description': message[item][3],
                 'trend': [],
                 'top': [],
             }
             datasets.push({
                 text: message[item][2],
                 value: message[item][1],
             });
         }
     }
     service_data['api2'] = replacement_list;
     service_data['datasets'] = datasets;
     if (datasets.length > 0 && service_data['whattrend'] === undefined) {
         console.log("-------------------------");
         console.log(datasets[0]);
         service_data['whattrend'] = datasets[0]['value'];
     }
     console.log("full api2:")
     console.log(service_data['api2']);

 }

 var await_fetch_datatypes = async function(identifiers) {
     await fetch_datatypes(identifiers);
 }

 var check_if_charted = function(cache_at) {
     for(var i = 0; i <  service_data['chartdata_raw'].length; i++) {
         if (service_data['chartdata_raw'][i]['cache_at'] == cache_at) {
             return true;
         }
     }
     return false;
 }

 var remove_chart = function(cache_at, value) {
     var new_chart_raw = []
     var new_chart_percent = []

     for(var i = 0; i <  service_data['chartdata_raw'].length; i++) {
         if (service_data['chartdata_raw'][i]['cache_at'] != cache_at) {
             new_chart_raw.push(service_data['chartdata_raw'][i]);
             new_chart_percent.push(service_data['chartdata_percent'][i]);
         }
     }
     service_data['chartdata_raw'] = new_chart_raw;
     service_data['chartdata_percent'] = new_chart_percent;
     if (value in service_data['graph_keys']) {
         delete service_data['graph_keys'][value];
         service_data['graph_keys_str'] =
             Object.keys(service_data['graph_keys']).join(",")
         console.log("removed " + value + " -> " + service_data['graph_keys_str']);
     }
 };

 var calculate_fraction = function(data) {
     var result = [];
     for(var i = 0; i <  data.length; i++) {
         result.push([data[i][0], data[i][3]]);
     }
     return result;
 }

 var update_chart_url_data = function(value) {
     service_data['graph_keys'][value] = 1;
     service_data['graph_keys_str'] =
         Object.keys(service_data['graph_keys']).join(",")
     console.log(service_data['graph_keys']);
     console.log(service_data['graph_keys_str']);
     console.log("added " + value + " -> " + service_data['graph_keys_str']);
 }

 var add_chart = function(cache_at, value, data, title) {
     // don't double add
     if (check_if_charted(cache_at)) {
         return;
     }

     var fracts = calculate_fraction(data);
     var dataset_raw = {
         name: title,
         cache_at: cache_at,
         'value': value,
         'data': data,
         'rawdata': data,
         'fractdata': fracts,
     };

     service_data['chartdata_raw'].push(dataset_raw);

     var dataset_percent = {
         name: title,
         cache_at: cache_at,
         'value': value,
         'data': fracts,
         'rawdata': data,
         'fractdata': fracts,
     };

     service_data['chartdata_percent'].push(dataset_percent);
 };

 var get_value_data = async function(cache_at, path, value, is_in_novel) {
     if (! (cache_at in service_data['value_data'])) {
         if (! is_in_novel) {
             service_data['loading_chart'] = true;
         }
         console.log("fetching not cached: " + path + " => value=" + value);
         await fetch_one(path, value, service_data['tmp']);
         if (! is_in_novel) {
             service_data['loading_chart'] = false;
         }
             
         service_data['value_data'][cache_at] = 
             service_data['tmp'][value]['message'];
     }
     return service_data['value_data'][cache_at];
 }
 

 var fetch_and_add_chart = async function(cache_at, path, value) {
     var records = await get_value_data(cache_at, path, value);

     // get the values and title
     var label = records['data_name'];
     var data = records['data'];

     // add it to the existing data
     console.log("add_chart(" + label + "...");
     add_chart(cache_at, value, data, label);
 }

 var toggle_value_graph = async function(cachepoint, value) {
     // get the default path for "all data"
     var path = cachepoint;
     var cache_at = (typeof cachepoint !== 'undefined') ? cachepoint : value;

     // see if we have previously plotted it, if so remove it instead
     if (check_if_charted(cache_at)) {
         remove_chart(cache_at, value);
         return;
     }

     // display a loading message for slow loads
     service_data['loading_value'] = value
     if (service_data['goto_graph']) {
         goTo('#graph');
     }

     // if we don't have the data, load it into:  service_data['value_data'][value]
     fetch_and_add_chart(cache_at, path, value);
     update_chart_url_data(value)
 }

 var await_fetch_trend = async function() {
     await fetch_trend();
 }

 var fetch_trend = async function() {
     // fetch trend information for a given api2 tab
     //console.log("FETCHING TREND");
     //reset_checked_values();
     service_data['dataloading'] = true;
     var fetch_whattrend = service_data['whattrend']; // values, httpstatus, etc

     if (! fetch_whattrend || fetch_whattrend === "undefined") {
         console.log("undefined trend at this point");
         return;
     }

     construct_date_map();
     var offset = service_data['date_map'][service_data['date']];

     var promises = [];
     var data;
     var i;
     var service_name;
     var promise;
     var service_names = [];
     
     // collect all the trend data we want to monitor
     for(i = 0 ; i < service_data['trend_list'].length; i++ ) { // trend/top
         service_name = service_data['trend_list'][i];
         promise = fetch_one("/api/2/" + service_name + "/" +
                             fetch_whattrend + "/recent/" + offset);
         promise['service_name'] = service_name;
         promises.push(promise);
         service_names.push(service_name);
     }

     // start collecting the history trends
     offset = service_data['date_map'][service_data['date']] -
              service_data['compare_offset'];

     for(i = 0 ; i < service_data['trend_list'].length; i++ ) { // trend/top
         service_name = service_data['trend_list'][i];
         promise = fetch_one("/api/2/" + service_name + "/" +
                             fetch_whattrend + "/recent/" + offset);
         promise['service_name'] = "past_" + service_name;
         promises.push(promise);
         service_names.push("past_" + service_name);
     }

     // fetch site counts
     service_name = service_data['trend_list'][i];
     promise = fetch_one("/api/2/sites/" + fetch_whattrend);
     promise['service_name'] = "sites"
     promises.push(promise);
     service_names.push("sitecount");
     

     // wait for them all to be processed
     var results;
     // console.log("WAITING ON PROMISES");
     // console.log(promises)
     await Promise.allSettled(promises).then(values => { results = values; });

     // then handle the responding data
     for(i = 0 ; i < promises.length; i++ ) { // trend/top
         service_name = service_names[i];
         data = results[i].value;
         if (!('status' in data)) {
             console.log("  ILLEGAL DATA returned: ");
             console.log(data)
         } else if (data['status'] != "ok") {
             console.log("  ERROR server: " + data['message'])
         } else if (!data || data['message'].length == 0 ||
                    !(fetch_whattrend in service_data['api2'])) {
             console.log("  ZERO DATA");
         } else {
             // change some fractions to percents and round
             var rounded = percent_rows(data['message'], [6,8]);
             rounded = round_rows(rounded, [6,7,8]);
             // console.log("storing: " + fetch_whattrend + "," + service_name);
             service_data['api2'][fetch_whattrend][service_name] = rounded;
             service_data['api2'][fetch_whattrend][service_name + "_new"] = 0;
         }
         // console.log("fetched " + service_name + "/" + fetch_whattrend + ":");
         // console.log(data);
     }

     // calculate a formated date from the unix dates
     if (!(fetch_whattrend in service_data['api2'])) {
         service_data['api2'][fetch_whattrend] = {};
         service_data['api2'][fetch_whattrend]['sitecount'] = [];
     }
     for (i = 0;
         i < service_data['api2'][fetch_whattrend]['sitecount'].length;
         i++) {
         var row = service_data['api2'][fetch_whattrend]['sitecount'][i]
         row[2] = dateformat(row[0] * 1000, 'yyyy-mm-dd');
     }

     service_data['dataloading'] = false;
     service_data['currenttrend'] = service_data['api2'][fetch_whattrend];

     // console.log("new api2: ")
     // console.log(service_data['api2']);

     await compare_trends();

     service_data['dataloading'] = false;
     service_data['currenttrend'] = service_data['api2'][fetch_whattrend];
     // console.log("new api2: ")
     // console.log(service_data['api2']);
 }

 var set_field = function(arr, index, value) {
     if (arr.length <= index) {
         for(var i = arr.length ; i < index ; i++ ) {
             arr.push(false);
         }
         arr.push(value);
     } else {
         arr[index] = value;
     }
 }

 var find_novels = async function(ids, trend_name, trend_identifier) {
     //var table_id = service_data['tabletab'];
     console.log("FINDING NOVELS: " + ids.length + " in " + trend_name + " and " + trend_identifier);
     /* console.log(ids);
      * console.log(service_data['api2']) */
     for(var i = 0 ; i < ids.length ; i++) {
         var id = ids[i]['data'];
         var url_path = "/api/2/fetch/" + trend_identifier + "/" + id[0] +
                        "/" + service_data['graph_length'];
         var data = await get_value_data(url_path, url_path, id[0], true);
         /* console.log("checking " + ids[i]['key']);
          * console.log(data);
          */
         // mark new
         var real_id = ids[i]['key'];
         /* console.log(service_data['api2'][trend_identifier][trend_name][real_id]); */
         if (data.data.length < 10) {
             /* console.log("  marking novel of length " + data.data.length + " for key " + real_id);
              * console.log(ids[i]);
              * console.log(data); */
             // mark novel
             set_field(service_data['api2'][trend_identifier][trend_name][real_id],
                       10, true);
         }
     }
 }

 var compare_trends = async function() {
     // fetch trend information for a given api2 tab
     // console.log("comparing TREND");
     var fetch_whattrend = service_data['whattrend']; // values, httpstatus, etc
     for(var i = 0 ; i < service_data['trend_list'].length; i++ ) { // trend/top
         var new_ids = [];
         var new_count = 0;
         var service_name = service_data['trend_list'][i];
         var current_data = service_data['api2'][fetch_whattrend][service_name];
         var past_data = service_data['api2'][fetch_whattrend]["past_" + service_name];

         /* if (current_data && past_data) {
          *     console.log("comparing " + fetch_whattrend + "/" + service_name +
          *                 " for current=" + current_data.length +
          *                 " and older=" + past_data.length);
          * } else {
          *     console.log("comparing " + fetch_whattrend + "/" + service_name + " but is missing data");
          * }
          * console.log(current_data);
          * console.log(past_data); */
         for (var j = 0; j < current_data.length ; j++) {
             var found = false;
             for (var k = 0; k < past_data.length ; k++) {
                 if (current_data[j][0] === past_data[k][0]) {
                     found = true;
                     continue;
                 }
             }
             if (found) {
                 set_field(current_data[j], 9, false);  // aka NOT new
             } else {
                 set_field(current_data[j], 9, true);  // new
                 new_count += 1;
             }
             new_ids.push({
                 'data': current_data[j],
                 'key': j
             });
         }
         
         service_data['api2'][fetch_whattrend][service_name + "_new"] = new_count;
         service_data['total_count'] += new_count;
         await find_novels(new_ids, service_name, fetch_whattrend);
     }
     console.log("NEWIDS");
     console.log(new_ids);
 }

 var construct_date_map = function() {
     var date_map = { 'recent': -1};
     var startdate = new Date().getTime();
     for (var i = 0 ; i < 45 ; i++ ) {
         startdate -= 86400 * 1000;
         var date = dateformat(startdate, 'yyyy-mm-dd');
         date_map[date] = 0 - i;
     }
     service_data['date_map'] = date_map;
 }

 var init_url_value = function(object, token_name, url_token) {
     if (! url_token) {
         url_token = token_name;
     }
     service_data[token_name] = get_url_value(object, url_token,
                                              service_data[token_name]);
 }

 var init_url_int_value = function(object, token_name, url_token) {
     if (! url_token) {
         url_token = token_name;
     }
     service_data[token_name] = parseInt(get_url_value(object, url_token,
                                                        service_data[token_name]));
 }

 var mark_labeled_rows = function(label_column) {
     console.log(service_data['api2'])
     var table_id = service_data['whattrend'];
     console.log("starting " + table_id);
     for(var i = 0 ; i < service_data['trend_list'].length; i++ ) { // trend/top
         var trend_id = service_data['trend_list'][i];
         console.log("starting " + trend_id);
         for (var j = 0 ; j < service_data['api2'][table_id][trend_id].length ; j++ ) {
             var ident = service_data['api2'][table_id][trend_id][j][0];
             var url_path = "/api/2/fetch/" + table_id + "/" + ident +
                            "/" + service_data['graph_length'];
             
             if (service_data['api2'][table_id][trend_id][j].length > label_column  
                 && service_data['api2'][table_id][trend_id][j][label_column]) {
                 if (!check_if_charted(url_path)) {
                     console.log("  marking: " + url_path);
                     fetch_and_add_chart(url_path, url_path, ident)
                     update_chart_url_data(ident)
                 }
             }
         }
     }
 }


     export default {
         name: 'Trends',
         data: function() { return service_data; },
         props: ['identifiers'],
         components: {
         'TrendTable': TrendTable,
     },
     created() {
         service_data['initializing'] = true;
         init_url_value(this, 'date');
         init_url_int_value(this, 'graph_length');
         init_url_value(this, 'site');
         init_url_value(this, 'whattrend', 'trend');
         init_url_value(this, 'tabletab', 'table');

         await_fetch_datatypes(this.$props.identifiers);
         await_fetch_trend();
         service_data['initializing'] = false;

         // create a chart based on specified values
         var table_id = service_data['whattrend'];
         var graphs = get_url_value(this, 'graphs')
         if (!(table_id in service_data['api2'])) {
             service_data['api2'][table_id] = {};
         }
         if (graphs) {
             var graphlist = graphs.split(",").map(function(a) {return parseInt(a);});
             service_data['trend_rows_checked'] = graphlist;
             service_data['api2'][table_id]['rows_checked'] = graphlist;
             for (var i = 0 ; i < graphlist.length ; i++ ) {
                 var trend_id = graphlist[i];
                 var url_path = "/api/2/fetch/" + table_id + "/" + trend_id +
                                "/" + service_data['graph_length'];
                 fetch_and_add_chart(url_path, url_path, graphlist[i]);
                 console.log("  added " + graphlist[i] + " at creation");
             }
             console.log(service_data['trend_rows_checked'])
         }
     },
     computed: {
         date_list: function() {
             var date_list = ['recent'];
             var startdate = new Date().getTime();
             for (var i = 0 ; i < 45 ; i++ ) {
                 startdate -= 86400 * 1000;
                 var date = dateformat(startdate, 'yyyy-mm-dd');
                 date_list.push(date);
             }
             return date_list;
         },
     },
     methods: {
         compare_trends: compare_trends,

         mark_new: function() {
             mark_labeled_rows(9);
         },
         mark_novel: function() {
             mark_labeled_rows(10);
         },

         reset_checked_values: function() {
             console.log("RESETTING");
             service_data['chartdata_raw'] = [];
             service_data['chartdata_percent'] = [];
             service_data['trend_rows_checked'] = [];
             service_data['graph_keys'] = {};
             service_data['graph_keys_str'] = "";
             set_url_value(this, 'graphs', '');
         },

         receive_values: function(received_data) {
             console.log("got emit of ");
             console.log(received_data);
             var new_value = received_data['new_value'] 
             var table_id = received_data['table'];
             var data = received_data['data']

             // update the cross-table mapping
             service_data['api2'][table_id]['rows_checked'] = data;

             // plot it
             var trend_id = new_value;
             var url_path = "/api/2/fetch/" + table_id + "/" + trend_id  +
                            "/" + service_data['graph_length'];
             if (data.includes(new_value)) {
                 console.log("  add it to the graph: " + new_value);
                 toggle_value_graph(url_path, new_value);
             } else {
                 console.log("  remove it from the graph");
                 remove_chart(url_path, new_value);
             }

             //console.log(service_data['api2'][table_id]['rows_checked']);
         },

         graph_it: async function() {
             // graphing service_data['manual_graph']

             // post data
             var settings = {
                 method: 'post',
                 headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json',
                 },
                 body: JSON.stringify({ 'value': service_data['manual_graph']}),
             }
             var response = await fetch('/api/2/getid/'+ service_data['whattrend'],
                                        settings);
             response = await response.json();

             // XXX: handle status != ok

             var url_path = "/api/2/fetch/" + service_data['whattrend'] + "/" +
                            response['message'][0] + 
                            "/" + service_data['graph_length'];

             fetch_and_add_chart(url_path, url_path, response['message'][0]);
             service_data['manual_graph'] = "";

             update_chart_url_data(response['message'][0]);
         },
     },
     watch: {
         "date": function() {
             if (! service_data['initializing']) {
                 set_url_value(this, 'date', service_data['date']);
                 await_fetch_trend()
             }
         },
         // "site": fetch_service_data,
         'whattrend': function() {
             if (! service_data['initializing']) {
                 console.log("************************")
                 console.log(service_data['whattrend'])
                 set_url_value(this, 'trend', service_data['whattrend'], true);
                 await_fetch_trend();
             }
         },
         "graph_keys_str": function() {
             if (! service_data['initializing']) {
                 console.log("noticed change");
                 console.log(service_data['graph_keys_str']);
                 set_url_value(this, 'graphs', 
                               service_data['graph_keys_str']);
             }
         },
         'site': function() {
             if (! service_data['initializing']) {
                 set_url_value(this, 'site', service_data['site']);
             }
         },
         'tabletab': function() {
             if (! service_data['initializing']) {
                 set_url_value(this, 'table', service_data['tabletab'], true);
             }
         },
         'graph_length': function() {
             if (! service_data['initializing']) {
                 set_url_value(this, 'graph_length',
                               service_data['graph_length'], true);
             }
         },
         //'tabletab': await_fetch_trend,
         'top_diff': function() { filter_data(); },
         'top_percent': function() { filter_data(); },
         'total_count': function() {
             this.$emit('emit_total', service_data['total_count']);
         }
     }
 }

</script>

<style>
 .corrsection {
     background-color: "#ffffff";
 }
 .v-messages {
     min-height: 0px;
 }
</style>
