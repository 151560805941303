<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="mt-2">
                <h3>Newly discovered files by day</h3>
                <p>Note that these files are collected from honeypot
                    logs, and are almost exclusively malicious.</p> 

                <a href="/binaries/images/new-binary-list.png">
                    <v-img src="/binaries/images/new-binary-list.png" contain max-width="80%" />
                </a>

                <h3>Entries seen in advance of Virus Total</h3>

                <p>The following graph shows the percentage of
                cumulative files logged within the gift as known by
                VirusTotal.</p>

                <a href="/images/time-fract-counts.png">
                    <v-img src="/images/time-fract-counts.png" contain max-width="80%" max-height="600px" />
                </a>

                <p>Each horizontal bar in this graph represents a
                    potential malware file that appeared in GiFT first and
                    was later discovered in VirusTotal.  The left hand
                    edge of the bar shows the date a file appeared in
                    GiFT, and the right hand bar shows the date it
                    appeared in Virus Total.  The darker bars (red and purple)
                    are file identified by Malware Identification
                    Engines in Virus total as malware, and the lighter
                    bares (light blue and light green) have been
                    identified as not-malware by Virus Total engines.</p>
                <a href="/stats/gift-graphs/dino.png">
                    <v-img src="/stats/gift-graphs/dino.png" contain max-width="80%" max-height="600px" />
                </a>

                <h3 class="mt-10">Days in advance bar charts</h3>
                <p>The following barchats show the number of days
                    ahead of Virus Total that files appeared in the GiFT.
                </p>

                <a href="/binaries/images/binary-vt-delta-summary-cdf.png"><v-img src="/binaries/images/binary-vt-delta-summary-cdf.png" contain max-width="80%" /></a>

                <a href="/binaries/images/binary-vt-delta-summary.png"><v-img src="/binaries/images/binary-vt-delta-summary.png" contain max-width="80%" /></a>
                
                <a href="/binaries/images/binary-vt-delta-summary-zoomed.png"><v-img src="/binaries/images/binary-vt-delta-summary-zoomed.png" contain max-width="80%" /></a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 export default {
     name: 'MalwareTrends',
     data: function() { return {}; },
     props: ['user'],
     created() { },
 }
</script>
