<template>
  <v-alert v-if="! user" color="yellow">
      <strong>Permission Denied:</strong> an account is required for page access -- please 
      <v-btn color="primary"
             href="/login"
             class=""
             x-small>login</v-btn>
      <p v-if="content_information" class="mt-2">
          <strong>What you are missing here:</strong>
          {{content_information}}
      </p>
  </v-alert>
</template>
<script>
    export default {
        name: 'LoginRequired',
        props: ['content_information', 'user'],
    }
</script>
