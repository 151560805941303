<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="mt-2">
                <h3>AVClass labels</h3>

                <p>Each of the files collected in GiFT <b>and identified by VirusTotal</b> are
                    analyzed for their
                    <a href="https://github.com/malicialab/avclass">AVCLASS</a> labels
                    and broken down into the following graphs.</p>

                <v-card v-for="image in types" :key="image.tag" class="mb-5">
                    <v-card-title>{{image.description}}</v-card-title>
                    <v-card-text>
                        <a :href="`/binaries/images/vt-avclass-tags-${image.tag}.png`"><v-img :src="`/binaries/images/vt-avclass-tags-${image.tag}.png`" contain max-width="80%" /></a>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 var vtdata = {
     'types': [
         { tag: 'CLASS',
           description: 'Malware classes' },
         { tag: 'FAM',
           description: 'Malware families' },
         { tag: 'BEH',
           description: 'Malware Purpose' },
         { tag: 'FILE',
           description: 'Malware file types' },
         { tag: 'UNK',
           description: 'Other malware identifiers' },
     ],
 };

 export default {
     name: 'MalwareArches',
     data: function() { return vtdata; },
     props: [],
     created() { },
 }

</script>
