<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-3">
                    <v-card-title>Internet URI Suffix Trends</v-card-title>
                    <v-card-text>
                        <p>The following table shows URI suffixes
                            being targeted by malicious addresses on
                            the Internet.  The trends are taken on a
                            weekly basis, with rising trends
                            indicating increase activity in the last
                            week compared to the others.  URIs flagged
                            as <em>new</em> indicate a port that was
                            not seen at all in the previous weeks data
                            and may reflecting emerging threats or new
                            vulnerabilities in web hosting software.
                            Hover over truncated URLs to see the full
                            length value.</p>


                    <v-simple-table dense>
                        <tbody>
                            <tr><th>URI Suffix</th><th>Trend</th><th></th></tr>
                            <tr v-for="row in uris" :key="row.uri">
                                <td>
                                    <v-tooltip top>
                                        <template v-slot:activator="{on,attrs}">
                                            <span class="tdwidth" v-bind="attrs" v-on="on">
                                                {{truncateText(row.uri)}}
                                            </span>
                                        </template>
                                        <span>{{row.uri}}</span>
                                    </v-tooltip>
                                </td>
                                <td v-if="row.diff > 0">
                                    <v-icon color="green">mdi-arrow-up-thick</v-icon></td>
                                <td v-if="row.diff == 0">
                                    <v-icon>mdi-arrow-right-thick</v-icon></td>
                                <td v-if="row.diff < 0">
                                    <v-icon color="red">mdi-arrow-down-thick</v-icon></td>
                                <td>
                                    <v-chip
                                        v-if="row.left == 0"
                                        x-small
                                        color="red"
                                        text-color="white">NEW</v-chip>
                                </td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import { fetch_one } from "../utils.js";

 var uri_data = {
     'uris': {}
 }

 export default {
     name: 'URIBriefing',
     data: function() { return uri_data; },
     props: [],
     created() { fetch_uri_data() },
     methods: {
         truncateText (value) {
             const length = 60;
             return value.length <= length ?
                    value : value.substring(0, length) + "...";
         }
     }
 }

 var fetch_uri_data = async function() {
     // load correlations
     await fetch_one("/data/http-method.json", 'uris', uri_data);
     
     console.log("loaded uris")
     console.log(uri_data['uris'])
 }
</script>

<style>
 .corrsection {
     background-color: "#ffffff";
 }
 .tdwidth {
     width: 200px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
 }
</style>
