 <template>
    <v-container>
        <v-row>
            <v-col cols="12" v-if="! user">
                <LoginRequired
                    content_information="This page allows users to search for IP addresses that we may have in our records, and if found will show historical content related to commands they may have executed in honeypots, their history of activity over time, and what other IP addresses we have grouped their behaviour with."
                >
              </LoginRequired>
            </v-col>
            <v-col cols="12" v-if="user">
                <v-card class="mb-3">
                    <v-card-title>
                        <h2>IP Address History</h2>
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Search for IP address details in the event history.
                        </p>
                    </v-card-text>
                </v-card>

                <v-card class="mb-3">
                    <v-card-title>IP Address</v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="2">
                                <strong>IP Address:</strong>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    v-model="ip_search_field"
                                    hint="Enter an IP address here">
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="load_ipaddress()"
                                       class="mt-3"
                                       x-small>Search</v-btn>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="reset_ipaddress()"
                                       class="mt-3"
                                       x-small>Reset</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-tabs v-model="tab" class="justify-center">


                    <!-- ============== honeypot ========== -->
                    <v-tab class="mb-3" v-if="ipaddress_count.length > 0"
                           href="#honeypot">
                        Honeypot Activity
                    </v-tab>
                    <v-tab-item value="honeypot">
                        <v-alert v-if="honeyerror" color="yellow">
                            {{honeyerror}}
                        </v-alert>
                        <v-card class="mb-5" v-if="ipaddress_count.length > 0">
                            <v-card-title v-if="ipaddress_count.length == 1">
                                Activity timeline for {{ipaddress_count[0]['name']}}:
                            </v-card-title>
                            <v-card-title v-if="ipaddress_count.length > 1">
                                Activity timeline:
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="9">
                                        <line-chart
                                            adapter="highcharts"
                                            :data="ipaddress_count"
                                            ytitle="IP address activity level"
                                            :curve="false"
                                        >
                                        </line-chart>
                                    </v-col>
                                    <v-col cols="3" v-if="ipaddress_clusters.length > 0">
                                        <h3>Cluster membership for {{searched_address}}</h3>
                                        <ul>
                                            <li v-for="cluster, n in ipaddress_clusters"
                                                :key="n">
                                                <a :href="cluster[1]">{{cluster[0]}}</a>
                                            </li>
                                        </ul>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </v-card>

                        <v-card class="mb-5" v-if="commands_address && recent_commands.length > 0">
                            <v-card-title>Recent Honeypot Commands Issued by {{commands_address}}</v-card-title>
                            <v-card-text>
                                <v-data-table
                                    :items="recent_commands"
                                    :headers="recent_headers">
                                </v-data-table>
                            </v-card-text>
                        </v-card>

                    </v-tab-item>

                    <!-- ============== SANS ========== -->
                    <v-tab class="mb-3" v-if="sans_results"
                           href="#sans">
                        SANS Information
                    </v-tab>
                    <v-tab-item value="sans">
                        <v-simple-table v-if="sans_results" dense>
                            <tbody>
                            <tr v-if="sans_results && sans_results['network']">
                                <th class="text-left">Network</th>
                                <td>{{sans_results["network"]}}</td>
                            </tr>
                            <tr><th class="text-left">AS</th><td>{{sans_results["as"]}}</td></tr>
                            <tr><th class="text-left"></th><td>{{sans_results["asname"]}}</td></tr>
                            <tr v-if="sans_results['cloud']">
                                <th class="text-left">Cloud</th>
                                <td>{{sans_results["cloud"]}}</td>
                            </tr>
                            <tr v-if="sans_results['attacks']">
                                <th class="text-left">Recent attacks</th>
                                <td>{{sans_results["attacks"]}}</td>
                            </tr>
                            <tr v-if="sans_results['count']">
                                <th class="text-left">Count</th>
                                <td>{{sans_results["count"]}}</td>
                            </tr>
                            <tr v-for="(key, index) in sans_results['threatfeeds']"
                                :key="`sans-threatfeeds-${index}`">
                                <th class="text-left">threatfeed: {{index}}</th>
                                <td>{{key["firstseen"]}} -- {{key["lastseen"]}}</td>
                            </tr>
                            <tr v-if="sans_results['comment']">
                                <th class="text-left">Comment</th>
                                <td>{{sans_results["comment"]}}</td>
                            </tr>
                            </tbody>                            
                        </v-simple-table>
                    </v-tab-item>

                    <!-- ============== SHODAN ========== -->
                    <v-tab class="mb-3" v-if="shodan_results"
                           href="#shodan">
                        SHODAN Information
                    </v-tab>
                    <v-tab-item value="shodan">
                        <v-simple-table v-if="shodan_results" density="compact" d-flex dense>
                            <tbody>
                            <tr v-if="shodan_results['country_code']">
                                <th class="text-left"
                                    style="vertical-align: top;">Location</th>
                                <td>
                                    {{shodan_results["country_code"]}}
                                    <span v-if="shodan_results['region_code']"> - {{shodan_results["region_code"]}}</span>
                                    <span v-if="shodan_results['city']"> - {{shodan_results["city"]}}</span>
                                    <div v-if="shodan_results['latitude'] && shodan_results['longitude']">
                                        <a href="`https://www.google.com/maps/@${shodan_results['latitude']},${shodan_results['longitude']}`">
                                            {{shodan_results['latitude']}}, {{shodan_results['longitude']}}
                                        </a>
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="shodan_results['domains']">
                                <th class="text-left align-self-start toptext"
                                    style="vertical-align: top;">Domains</th>
                                <td>
                                    <div v-for="domain in shodan_results['domains']"
                                       :key="domain">
                                        {{domain}}
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="shodan_results['hostnames']">
                                <th class="text-left align-self-start toptext"
                                    style="vertical-align: top;">Hostnames</th>
                                <td>
                                    <div v-for="hostname in shodan_results['hostnames']"
                                       :key="hostname">
                                        {{hostname}}
                                    </div>
                                </td>
                            </tr>
                            <tr v-if="shodan_results['org']">
                                <th class="text-left">Organization</th>
                                <td>
                                    {{shodan_results['org']}}
                                </td>
                            </tr>
                            <tr v-if="shodan_results['isp']">
                                <th class="text-left">ISP</th>
                                <td>
                                    {{shodan_results['isp']}}
                                </td>
                            </tr>
                            <tr v-if="shodan_results['asn']">
                                <th class="text-left">ASN</th>
                                <td>
                                    {{shodan_results['asn']}}
                                </td>
                            </tr>


                            <tr v-for="(service, index) in shodan_results['data']"
                                :key="`shodan-${index}`">
                                <th class="text-left">Open port {{service['port']}}</th>
                                <td>{{service['product']}}</td>
                            </tr>
                            </tbody>
                        </v-simple-table>
                    </v-tab-item>


                </v-tabs>
            </v-col>
        </v-row>
    </v-container>
 </template>

 <script>
  import Vue from 'vue';
  import Chartkick from 'vue-chartkick';
  import Highcharts from 'highcharts';
  import LoginRequired from '../components/LoginRequired';
  import { get_url_value, set_url_value } from '../utils.js'

  Vue.use(Chartkick.use(Highcharts))

  import { fetch_one } from "../utils.js";
  var ipsearch_data = {
      'recent_commands': [],
      'recent_headers': [
          {'text': 'Date', 'value': 'datestr', sortable: true},
          {'text': 'Commands', 'value': 'commands', sortable: true},
      ],
      searched_address: "",
      searched_address_list: [],
      ip_search_field: "",
      ip_search_results: {},
      ipaddress_count: [],
      ipaddress_clusters: [],
      honeyerror: '',
      commands_address: '',
      cluster: '',
      cluster_membership: [],

      sans_results: undefined,
      sans_address: undefined,
      sans_error: undefined,

      shodan_results: undefined,
      shodan_address: undefined,
      shodan_error: undefined,

      tab: '',
  }

  var load_honeypot_counts = async function(address) {
      // collect the timeline
      var new_counts = 
          await fetch_one("/enrich/haas/ip-counts/" +
                          address);
      ipsearch_data["honeyerror"] = '';

      if (new_counts && new_counts["ip_counts"] && new_counts["ip_counts"].length > 0) {
          var data_struct = {}
          console.log(new_counts)
          new_counts["ip_counts"].forEach(function(value_set) {
              var ourdate = new Date(1000 * value_set[0])
              var datestr = ourdate.getFullYear() + "-" + ourdate.getMonth() + "-" + ourdate.getDate()
              data_struct[datestr] = value_set[2]
          })

          var dataset = {
              name: address,
              'data': data_struct,
          }
          ipsearch_data["ipaddress_count"].push(dataset);
      } else {
          ipsearch_data["ipaddress_count"] = [];
          ipsearch_data["honeyerror"] = "No count data for '" + address + "'";
      }
  }

  var load_honeypot_commands = async function(address) {
      // collect the command line
      var command_list =
          await fetch_one("/enrich/haas/ip/" +
                          address);

      console.log("command_list");
      console.log(command_list);
      if (command_list && command_list["commands"] && command_list["commands"].length > 0) {
          ipsearch_data["ip_search_results"] = command_list

          ipsearch_data["recent_commands"] = []
          ipsearch_data['ip_search_results']["commands"].forEach(function(item) {
              var d = new Date(1000 * item[0])
              ipsearch_data['recent_commands'].push({'datestr': d.toUTCString(),
                                                     'commands': item[1]});
          });
          ipsearch_data["commands_address"] = address;
          ipsearch_data["honeyerror"] = undefined;
          console.log("saving commands: ");
          console.log(ipsearch_data["recent_commands"])
      } else {
          ipsearch_data["ip_search_results"] = [];
          ipsearch_data["recent_commands"] = [];
          ipsearch_data["honeyerror"] = "No honeypot command data for '" + address + "'";
      }
  }

  var load_honeypot_clusters = async function(address) {
      // ip_clusters
      var ip_clusters = 
          await fetch_one("/enrich/haas/ip-clusters/" + address);

      if (ip_clusters && ip_clusters["clusters"] && ip_clusters["clusters"].length > 0) {
          ipsearch_data["ipaddress_clusters"] = [];
          ip_clusters["clusters"].forEach(function(value) {
              ipsearch_data["ipaddress_clusters"].push([value[0], "/#/?tab=ipcluster&ipcluster=" + value[0]])
          });
      } else {
          ipsearch_data["ipaddress_clusters"] = [];
      }
      console.log("*********** clusters: ", ip_clusters);
  }

  var load_sans_info = async function(address) {
      // collect the command line
      var sans_info =
          await fetch_one("/enrich/sans/ip/" + address);

      console.log("sans_info");
      console.log(sans_info);
      if (sans_info && sans_info["ip"]) {
          ipsearch_data["sans_results"] = sans_info["ip"];
          ipsearch_data["sans_address"] = address;
          ipsearch_data["sans_error"] = undefined;
      } else {
          ipsearch_data["sans_results"] = undefined;
          ipsearch_data["sans_address"] = undefined;
          ipsearch_data["sans_error"] = "No SANS data for '" + address + "'";
      }
  }

  var load_shodan_info = async function(address) {
      // collect the command line
      var shodan_info =
          await fetch_one("/enrich/shodan/ip/" + address);

      console.log("shodan_info");
      console.log(shodan_info);
      if (shodan_info && shodan_info["ip"]) {
          ipsearch_data["shodan_results"] = shodan_info;
          ipsearch_data["shodan_address"] = address;
          ipsearch_data["shodan_error"] = undefined;
      } else {
          ipsearch_data["shodan_results"] = undefined;
          ipsearch_data["shodan_address"] = undefined;
          ipsearch_data["shodan_error"] = "No shodan data for '" + address + "'";
      }
  }

  var load_ipaddress = async function(ip) {
      if (! ip &&
          ipsearch_data["ip_search_field"] === "" &&
          ipsearch_data["searched_address"] === "") {
          return;
      }
      if (ip) {
          ipsearch_data["ip_search_field"] = ip;
      }
      if (ipsearch_data["ip_search_field"] !== "") {
          ipsearch_data["searched_address"] = ipsearch_data["ip_search_field"];
          ipsearch_data["searched_address_list"].push(ipsearch_data["ip_search_field"]);
          ipsearch_data["ip_search_field"] = "";
      }
      var address = ipsearch_data["searched_address"];

      await load_honeypot_counts(address);
      await load_honeypot_clusters(address);
      await load_honeypot_commands(address);
      await load_sans_info(address);
      await load_shodan_info(address);
      //set_url_value(this, 'tab', 'ipsearch');
  }

  var load_one_address = async function(address) {
      ipsearch_data["ip_search_field"] = address;
      await load_ipaddress();
  }

  var load_cluster = async function(clusternum) {
      var cluster_ips = 
          await fetch_one("/enrich/haas/cluster-ips/" + clusternum);
      if (cluster_ips && cluster_ips.ips.length > 0) {
          ipsearch_data["cluster_membership"] = cluster_ips.ips;
          ipsearch_data["cluster"] = clusternum
      }
  }

  export default {
      name: 'IPSearch',
      data: function() { return ipsearch_data; },
      props: ['user'],
      created() {
          var searched_addresses = get_url_value(this, 'ipaddress');
          if (searched_addresses) {
              searched_addresses = searched_addresses.split(",");

              searched_addresses.forEach(function(address) {
                  load_one_address(address);
              })
              if (ipsearch_data["searched_address_list"].length > 0) {
                  ipsearch_data["searched_address"] = ipsearch_data["searched_address_list"][0];
              }
          } else {
              ipsearch_data["searched_address"] = [];
          }

      },
      methods: {
          'load_ipaddress': load_ipaddress,
          'load_cluster': load_cluster,
          'reset_ipaddress': function() {
              ipsearch_data["ipaddress_count"] = [];
              ipsearch_data["searched_address_list"] = [];
              ipsearch_data["command_list"] = [];
              set_url_value(this, 'ipaddress', "");
          }
      },
      watch: {
          "searched_address_list": function() {
              set_url_value(this, 'ipaddress',
                            ipsearch_data['searched_address_list'].join(","));
          }
      },
      components: {
         LoginRequired,
      },
  };
 </script>

 <style>
  .toptext: {
      vertical-align: top !important;
  }
 </style>
