<template>
  <v-app>
      <router-view></router-view>
      <v-main>
          <v-container>
              <v-banner>
                  <h3>GiFT Malware Content Information</h3>
              </v-banner>
              <v-alert color="yellow">
                  <p>
                      This data is no longer currently maintained
                      unfortunately, as we no longer have a VirusTotal
                      API key.  VirusTotal generously granted us a key
                      for multiple years but were not able to continue
                      providing us service indefinitely.
                  </p>
              </v-alert>
              <v-tabs v-model="tab">
                  <v-tab href="#summary">Malware Summary</v-tab>
                  <v-tab-item value="summary">
                      <MalwareSummary/>
                  </v-tab-item>

                  <v-tab href="#trends">Collection Trends</v-tab>
                  <v-tab-item value="trends">
                      <MalwareTrends/>
                  </v-tab-item>

                  <v-tab href="#avtrends">Malware AVCLASS trends</v-tab>
                  <v-tab-item value="avtrends" v-if="!user">
                      <LoginRequired
                          content_information="trends in avclass analysis of malware collected by the GiFT system.">
                      </LoginRequired>
                  </v-tab-item>
                  <v-tab-item value="avtrends" v-if="user">
                      <Trends
                          identifiers="['avclass_FAM',
                                       'avclass_FILE',
                                       'avclass_BEH',
                                       'avclass_CLASS',
                                       'avclass_UNK']">
                      </Trends>
                  </v-tab-item>

                  <v-tab href="#yaratrends">Malware YARA trends</v-tab>
                  <v-tab-item value="yaratrends" v-if="!user">
                      <LoginRequired
                          content_information="trends in YARA analysis of malware collected by the GiFT system.">
                      </LoginRequired>
                  </v-tab-item>
                  <v-tab-item value="yaratrends" v-if="user">
                      <Trends
                          identifiers="['yara_email',
                                       'yara_capabilities',
                                       'yara_malware',
                                       'yara_maldocs',
                                       'yara_crypto',
                                       'yara_packers']">
                      </Trends>
                  </v-tab-item>

                  <v-tab href="#avtypes">Malware AVCLASS analysis</v-tab>
                  <v-tab-item value="avtypes">
                      <MalwareVTTypes/>
                  </v-tab-item>

                  <v-tab href="#types">File type analysis</v-tab>
                  <v-tab-item value="types">
                      <MalwareTypes/>
                  </v-tab-item>
              </v-tabs>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>
 import MalwareSummary from '../components/MalwareSummary';
 import MalwareTypes from '../components/MalwareTypes';
 import MalwareVTTypes from '../components/MalwareVTTypes';
 import MalwareTrends from '../components/MalwareTrends';
 import Trends from '../components/Trends';
 import LoginRequired from '../components/LoginRequired';
 
 export default {
     name: 'MalwareView',
     
     props: ['user'],
     components: {
         MalwareSummary,
         MalwareTypes,
         MalwareVTTypes,
         MalwareTrends,
         Trends,
         LoginRequired,
     },
     
     data: () => ({
         main_tabs: "0",
     }),

     computed: {
         tab: {
             set(tab) {
                 this.$router.replace({ query: { ...this.$route.query, tab}})
             },
             get() {
                 return this.$route.query.tab
             }
         }
     },
 };
</script>
