<template>
  <v-app>
      <v-main>
          <router-view></router-view>
          <v-container>
              <v-banner>
                  <h3>Global Threats Briefing</h3>
              </v-banner>
              <LoginRequired
                  content_information="This page is updated daily with trend graphs showing newly detected malicious activity trends relating to IP address activity, port scanning, DNS backscatter detection, temporal honeypot analysis, and multi-honeypot analysis."
                  :user="user"
              >
              </LoginRequired>
              <v-tabs v-model="tab" class="justify-center" v-if="user">
                  <!-- <v-tab href="#summary">Briefing Summary</v-tab> -->
                  <v-tab href="#servicetrends">
                      Traffic Trends
                      <v-badge
                          v-if="new_trend_count > 0"
                          :content="new_trend_count"
                          :value="new_trend_count"
                          class="mb-2"
                          color="red"
                      >
                      </v-badge>
                      <v-badge
                          v-if="rising_service_count > 0"
                          :content="rising_service_count"
                          :value="rising_service_count"
                          class="mb-2"
                          color="orange"
                      >
                      </v-badge>
                  </v-tab>
                  <v-tab href="#dnstrends">
                      DNS Backscatter Trends
                      <v-badge
                          v-if="new_dnstrend_count > 0"
                          :content="new_dnstrend_count"
                          :value="new_dnstrend_count"
                          class="mb-2"
                          color="red"
                      >
                      </v-badge>
                  </v-tab>
                  <v-tab href="#correlations">
                      TTP Correlation Report 
                      <v-badge
                          v-if="new_ttp_count > 0"
                          color="red"
                          class="mb-2"
                          :content="new_ttp_count">
                      </v-badge>
                  </v-tab>

                  <!-- <v-tab href="#timemotif">
                      Time Motif Report 
                  </v-tab> -->

                  <v-tab href="#temporalhoneypot">
                      Temporal Honeypot Analysis 
                  </v-tab>

                  <v-tab href="#multihoneypot">
                      Multi-honeypot Analysis 
                  </v-tab>

                  <!-- <v-tab-item value="summary">
                      <GlobalDailyBriefing/>
                  </v-tab-item> -->
                  <v-tab-item value="servicetrends">
                      <Trends
                          identifiers="['port','dns','httpurianon','httpmethod','httpstatus','irccommands','kerberosservice']"
                          @emit_total="receive_total_count">
                      </Trends>
                  </v-tab-item>
                  <v-tab-item value="dnstrends">
                      <Trends
                          identifiers="['dns_backscatter_classifications', 'dns_backscatter_ips', 'dns_backscatter_domains', 'dns_backscatter_isps', 'dns_backscatter_usageTypes']"
                          @emit_total="receive_total_count"
                          :user="user">
                      </Trends>
                  </v-tab-item>
                  <v-tab-item value="uris">
                      <URIBriefing/>
                  </v-tab-item>
                  <v-tab-item value="correlations">
                      <CorrelationReport/>
                  </v-tab-item>
                  <v-tab-item value="timemotif">
                      <TimeMotifReport/>
                  </v-tab-item>
                  <v-tab-item value="temporalhoneypot">
                      <TemporalHoneypot/>
                  </v-tab-item>
                  <v-tab-item value="multihoneypot">
                      <MultiHoneypot/>
                  </v-tab-item>
              </v-tabs>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>
 //import GlobalDailyBriefing from '../components/GlobalDailyBriefing';
 import CorrelationReport from '../components/CorrelationReport';
 //import TimeMotifReport from '../components/TimeMotifReport';
 import Trends from '../components/Trends';
 import URIBriefing from '../components/URIBriefing';
 import MultiHoneypot from '../components/MultiHoneypot';
 import TemporalHoneypot from '../components/TemporalHoneypot';
 import LoginRequired from '../components/LoginRequired';
 import { get_url_value, set_url_value } from '../utils.js'
 
 var counter_data = {
     'new_ttp_count': 0,
     'new_uri_count': 0,
     'new_trend_count': 0,
     'new_dnstrend_count': 0,
     'rising_service_count': 0,
     'correlation_data': {},
     'service_data': {'message': {'all': {'total_sites': 0}}},
 };

 var receive_total_count = function(val) {
     counter_data['new_trend_count'] = val;
     console.log("received count: " + val);
 }

 export default {
     name: 'DailyBriefing',
     props: ['user'],
     
     components: {
         //GlobalDailyBriefing,
         CorrelationReport,
         Trends,
         URIBriefing,
         //TimeMotifReport,
         MultiHoneypot,
         TemporalHoneypot,
         LoginRequired,
     },
     
     data: function() { return counter_data; },

     computed: {
         tab: {
             set(tab) {
                 set_url_value(this, 'tab', tab, true)
             },
             get() {
                 return get_url_value(this, 'tab');
             }
         }
     },

     methods: {
         'receive_total_count': receive_total_count,
     }
 };
</script>
