<template>
  <v-app>
      <v-main>
          <GiftBanner
              @annotate="toggle_annotation()"
              :user="user"
          />
          <NavMenu/>
          <v-alert
              class="ml-5 mr-5 mt-3"
              v-if="annotation_message"
              :type="message_type"
              elevation="3"
              dismissible>
              {{annotation_message}}
          </v-alert>
          <Annotate v-if="show_annotation"
                    @done_annotating="done_annotating">
          </Annotate>
          <router-view :user="user"></router-view>
      </v-main>
  </v-app>
</template>

<script>
 import GiftBanner from './components/GiftBanner';
 import NavMenu from './components/NavMenu';
 import Annotate from './components/Annotate';
 import { fetch_one } from "./utils.js";
 
 var main_app_data = {
     main_tabs: "0",
     show_annotation: false,
     annotation_message: "",
     message_type: 'success',
     user: undefined,
 }

 export default {
     name: 'App',
     
     components: {
         GiftBanner,
         NavMenu,
         Annotate,
     },
     
     methods: {
         done_annotating(message) {
             main_app_data['show_annotation'] = false;
             main_app_data['annotation_message'] = message['message'];
             main_app_data['message_type'] =
                 (message['status'] === "ok") ? "success" : "warning";
         },
         toggle_annotation() {
             main_app_data['show_annotation'] = ! main_app_data['show_annotation'];
             main_app_data['annotation_message'] = "";
         },
     },
     created: async function() {
         if (! main_app_data['user']) {
             var data = await fetch_one("/api/user/info", 'user');
             if (data && data['message']['authenticated'] && data['message']['login']) {
                 main_app_data['user'] = data['message']['login'];
             }
         }
         console.log("user: ", main_app_data['user']);
     },
     data() { return main_app_data; },
 };
</script>
<style>
 nav router-link-exact-active,
 nav router-link-active {
     color: "red";
     background-color: "indianred";
 }
 .v-btn {
     color: "red";
 }
</style>
