<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-3">
                    <v-card-title>
                        <h2>Cross-Honeypot Analysis</h2>
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Below is a graphical representation of commands being executed on multiple different honeypots.
                        </p>

                        <v-select label="Date"
                                  v-model="date"
                                  :items="date_list">
                        </v-select>
                        <v-img :src="image_path"
                               contain>
                        </v-img>
                    </v-card-text>
                </v-card>
                <v-card class="mb-3">
                    <v-card-title>
                        Command Samples
                    </v-card-title>
                    <v-card-text>
                        <p>This table shows example commands represented by the numbers in the circles in the graph above. Note that commands may vary significantly with different parameters, but have been clustered together to appear as a single node using GAWSEED algorithms.</p>
                        <v-data-table
                            :items="clusterlist"
                            :headers="clusterlist_headers"
                            :sort-by="['cluster_id']"
                        ></v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import { fetch_one } from "../utils.js";
 import { get_url_value, set_url_value } from '../utils.js'

 var multihoneypot_data = {
     'date': "recent",
     'image_path': '/data/honeypot-distance-graph/recent-distancegraph.png',
     'date_list': ['recent'],
     'clusterlist': [],
     'clusterlist_headers': [
         {'text': 'Cluster Id', 'value': 'node1_id', sortable: true},
         {'text': 'Command', 'value': 'node1', sortable: true},
     ],
 }

 var fetch_date_list = async function() {
     var date_list = await fetch_one('/data/honeypot-distance-graph/date-list.json');
     date_list.unshift('recent');
     multihoneypot_data['date_list'] = date_list;
     console.log("loaded date list:");
     console.log(date_list);
 }

 var fetch_data_for_date = async function(date) {
     console.log("initial date: " + date);
     if (!date) {
         date = multihoneypot_data['date']
     }

     console.log("initial2 date: " + date);
     if (date === "recent") {
         console.log(multihoneypot_data['date_list']);
         // pull out the most recent date to use

         if (multihoneypot_data['date_list'].length == 1) {
             await fetch_date_list();
         }

         date = multihoneypot_data['date_list'][1];
     }

     console.log("fetching data for " + date);
     var clusterlist = await fetch_one('/data/honeypot-distance-graph/' + date +
                                       '-commands.json');
     
     console.log("assigning");
     console.log(clusterlist);
     multihoneypot_data['clusterlist'] = clusterlist;
     multihoneypot_data['image_path'] = '/data/honeypot-distance-graph/' +
                                    date + '-distancegraph.png';
     console.log(multihoneypot_data['clusterlist']);
 }

 export default {
     name: 'TimeMotifReport',
     data: function() { return multihoneypot_data; },
     created() {
         multihoneypot_data['date'] = get_url_value(this, 'date', 'recent');
         fetch_data_for_date();
     },
     watch: {
         'date': function() {
             set_url_value(this, 'date', multihoneypot_data['date']);
             fetch_data_for_date();
         }
     }
 }
</script>

<style>
    .corrsection {
        background-color: "#ffffff";
    }
</style>
