<template>
    <v-container>
        <v-row>
            <v-col cols="12">
		<v-img src="https://gift.ant.isi.edu/images/gift.svg"
                       contain max-height="2.25em" class="float-right" />
                <span v-if="! user">
                    <v-btn color="primary"
                           href="/login"
                           class="float-right mt-1"
                           x-small>Login</v-btn>
                </span>
                <span v-if="user && user === 'hardaker'">
                    {{user}} <br />
                    <v-btn color="primary"
                           @click="open_annotations()"
                           class="float-left mt-1"
                           x-small>Annotate</v-btn>
                </span>
		<span class="d-flex gift-banner justify-center"
                      align="center"
                      justify="center">
                    GAWSEED Global Threat Detection Resources
                </span>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 export default {
     name: 'GiftBanner',
     props: ['user'],
     methods: {
         open_annotations() {
             this.$emit('annotate', {});
         }
     },
 }
</script>
<style>
 .gift-banner {
     font-size: 1.75em;
     font-weight: bold;
 }
</style>
