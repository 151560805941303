<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-2">
                    <v-card-title>GAWSEED Internet Feed of Threats (GiFT)</v-card-title>
                    <v-card-text>
                        <p>
                        USC/ISI's GiFT feed contains an aggregated, at
                        least daily updated stream of Indicators of
                        Compromise (IOCs) from multiple sources. The
                        data is published as tab-separated files, over
                        <a href="https://kafka.apache.org/">Apache Kafka</a> in
                        a
                        <a href="feed-descriptions.html">documented JSON format</a>
                        and as multiple, subscribable
                        <a href="https://www.misp-project.org/">MISP</a> feeds (see below).
                        These feeds can then be used by search tools, such as USC/ISI's
                        <a href="https://github.com/gawseed/threat-feed-tools">threat-feed-tools</a>, a 
                        Threat Intelligence Platform that can search network
                        logs (e.g. <a href="https://zeek.org/">zeek/bro</a>)
                        for potential suspicious activity,
                        creating reports for every finding.
                        </p>
                        <p>Want a daily briefing sent to your Inbox?  Sign up to our <a href="https://mailman.isi.edu/mailman/listinfo/daily-security-briefings">Daily Security Briefing</a> mailing list (which isn't actually daily, as reports are sent only after important events have been discovered that day).</p>

                        <p v-if="!user">
                            <strong>Note:
                            </strong>
                            Some features require a login to access
                            the more powerful data analytic sections.
                            Please
                            <v-btn color="primary"
                                   href="/login"
                                   class=""
                                   x-small>login</v-btn>
                            to view these sections.
                            <br /> Contact hardaker
                            at ISI about obtaining an account.
                        </p>

                        <p><em>Note: redistribution of GiFT components
                            without permission is prohibited.  Please
                            have potential users reach out to
                            hardaker at ISI to request access.</em></p>
                    </v-card-text>
                </v-card>

                <v-card class="mb-2">
                    <v-card-title>General Feed Statistics</v-card-title>
                    <v-card>
                        <FeedGeneralStatistics></FeedGeneralStatistics>
                    </v-card>
                </v-card>

                <v-card class="mb-2">
                    <v-card-title>Explore the GiFT Datasets</v-card-title>
                    <v-card-text>
                        <p>These are some of our favorite data exploration links:</p>
                        <v-row>
                            <v-col cols="4">
                                <a href="/#/analysis">Analysis Results</a>
                            </v-col>
                            <v-col cols="4">
                                <a href="/#/dailybriefing?tab=dnstrends&trend=dns_backscatter_ips">DNS Backscatter IPs</a>
                            </v-col>
                            <v-col cols="4">
                                <a href="/#/dailybriefing?tab=servicetrends&trend=httpurianon">Anonymized URI Trends</a>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="4">
                                <a href="/#/dailybriefing?tab=servicetrends&trend=command">Honeypot Command Trends</a>
                            </v-col>
                            <v-col cols="4">
                                <a href="/#temporalhoneypot">Temporal Honeypot Analysis</a>
                            </v-col>
                            <v-col cols="4">
                                <a href="/#correlations">TTP Correlations</a>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card class="mb-2">
                    <v-card-title>FSDB / Tab-Separated Indicators of Compromise (IoCs) files</v-card-title>
                    <v-card-text>
                        <p>The following files contain tab-separated
                            (FSDB
                            formatted for
                            <a href="https://www.isi.edu/~johnh/SOFTWARE/FSDB/">
                                perl's
                            </a>
                            or
                            <a href="https://github.com/gawseed/pyfsdb">
                                python's
                            </a>
                            FSDB modules)
                            files of the last few days
                            worth of unique data.</p>
                        <v-simple-table>
                            <tr><th>File</th><th>Contents</th></tr>
                            <tr>
                                <td><a href="/data/threat-data/gawseed_ipaddresses.fsdb">gawseed_ipaddresses.fsdb</a>
                                </td>
                                <td>IP addresses from discovered bot network and other malicious sources in the last 5 days
                                </td>
                            </tr>
                            <tr>
                                <td><a href="/data/threat-data/gawseed_ipaddresses_safe.fsdb">gawseed_ipaddresses_safe.fsdb</a>
                                </td>
                                <td>IP addresses from acknowledge scanners and researchers seen in the last 5 days
                                </td>
                            </tr>
                            <tr>
                                <td><a href="/data/threat-data/gawseed_binaries.fsdb">gawseed_binaries.fsdb</a>
                                </td>
                                <td>Identified unique malware used in the last 14 days</td>
                            </tr>
                            <tr>
                                <td><a href="/data/threat-data/gawseed_dns_raw.fsdb">gawseed_dns_raw.fsdb</a>
                                </td>
                                <td><em>Beta:</em> Raw DNS records discovered in the last 3 days
                                </td>
                            </tr>
                            <tr>
                                <td><a href="/data/threat-data/gawseed_dns_regexp.fsdb">gawseed_dns_regexp.fsdb</a>
                                </td>
                                <td><em>Beta:</em> DNS regular expressions created in the last 3 days
                                </td>
                            </tr>
                            <tr>
                                <td><a href="/data/threat-data/gawseed_urls.fsdb">gawseed_urls.fsdb</a>
                                </td>
                                <td><em>Beta:</em> Regular expressions matching URLs identified in the last 3 days
                                </td>
                            </tr>
                        </v-simple-table>
                    </v-card-text>
                </v-card>

                <v-card class="mb-2">
                    <v-card-title>MISP Feeds</v-card-title>
                    <v-card-text v-if="!user">
                        <LoginRequired
                            content_information="MISP feeds of all GiFT IOCs">
                        </LoginRequired>
                    </v-card-text>
                    <v-card-text v-if="user">
                        <p>The following URLs offer MISP formatted
                            feeds of the data found in the GiFT kafka
                            feeds.</p>
                        <v-simple-table dense>
                            <thead>
                                <tr><th>Feed Description</th><th>URL</th><th>Datatype</th></tr>
                            </thead>
                            <tbody>
                                <tr v-for="(feed, index) in mispfeeds"
                                    :key="`misp-${index}`">
                                    <td style="padding-right: 5em;">{{feed.description}}</td>
                                    <td><a :href="feed.url">{{feed.url}}</a></td>
                                    <td>{{feed.datatype}}</td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                        <p class="mt-3"><strong>To add these to a MISP installation:</strong>
                            <ol>
                                <li>Go to <em>Sync Actions/List Feeds</em></li>
                                <li>In the left hand panel, select <em>Add Feed</em></li>
                                <li>Check at least <em>Enabled</em> and <em>Lookup visible</em></li>
                                <li>Add the feed name and the provider (<em>"USC/ISI"</em>)</li>
                                <li>Paste in the URL from the above table</li>
                                <li>Set the <em>Source Format</em> to <em>MISP Feed</em></li>
                                <li><b>Please:</b> Set distribution to <em>Your organization only</em></li>
                                <li>Click the <em>Add</em> button.</li>
                                <li>Optionally: on the next screen, click on <em>Fetch and store all feed data</em> to start collecting it immediately.</li> 
                            </ol>
                        </p>
                    </v-card-text>
                </v-card>
                <v-card class="mb-2">
                    <v-card-title>Kafka Access</v-card-title>
                    <v-card-text v-if="!user">
                        <LoginRequired
                            content_information="Apache kafka feeds of all IoC streams">
                        </LoginRequired>
                    </v-card-text>
                    <v-card-text v-if="user">
                        <p>Access GiFT contents via Kafka by connecting to
                            these servers and streaming the topics of
                            interest described in the
                            <em>Feed Descriptions</em> tab.</p>

                        <p><strong>GiFT bootstrap kafka servers:</strong></p>
                        <ul>
                            <li> k01.ant.isi.edu </li>
                            <li> k02.ant.isi.edu </li>
                        </ul>
                    </v-card-text>
                </v-card>
                <v-card class="mb-2">
                    <v-card-title>Acknowledgments</v-card-title>
                    <v-card-text>
                        <em>This research is based upon work supported
                            in part by DARPA, via W911NF-16-1-0575,
                            and the Office of the Director of National
                            Intelligence (ODNI), Intelligence Advanced
                            Research Projects Activity (IARPA), via
                            2016-16041100004. The views and
                            conclusions contained herein are those of
                            the authors and should not be interpreted
                            as necessarily representing the official
                            policies, either expressed or implied, of
                            DARPA, ODNI, IARPA, or the
                            U.S. Government. The U.S. Government is
                            authorized to reproduce and distribute
                            reprints for governmental purposes
                            notwithstanding any copyright annotation
                            therein.
                        </em>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import LoginRequired from '../components/LoginRequired';
 import FeedGeneralStatistics from '../components/FeedGeneralStatistics';

 var baseurl="https://gift.ant.isi.edu/misp/"
 var data = {
     'mispfeeds': [
         {'description': 'IP addresses',
          'url': baseurl + 'ips',
          'datatype': 'IP Address'},
         {'description': 'Malware Hashes',
          'url': baseurl + 'malware',
          'datatype': 'SHA256 hash'},
         {'description': 'Analysis Articles',
          'url': baseurl + 'articles',
          'datatype': 'Analysis Links'},
     ],
 };

 export default {
     name: 'About',
     data: function() {
         return data; },
     props: ['user'],
     components: {
         LoginRequired,
         FeedGeneralStatistics,
     },
     created: function() { console.log("staring user", data['user'])}
 }
</script>
