<template>
    <v-container>
        <v-row>
            <v-col cols="12" v-if="! user">
                <LoginRequired
                    content_information="This page allows users to search for IP addresses that we may have in our records, and if found will show historical content related to commands they may have executed in honeypots, their history of activity over time, and what other IP addresses we have grouped their behaviour with."
                >
              </LoginRequired>
            </v-col>
            <v-col cols="12" v-if="user">
                <v-card class="mb-3">
                    <v-card-title>
                        <h2>IP Cluster Analysis</h2>
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Show details for an IP address cluster number.
                        </p>
                        <v-row>
                            <v-col cols="4">
                                <strong>Behavioural IP Cluster Search:</strong>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field
                                    v-model="cluster_search"
                                    hint="Enter an IP cluster number:">
                                </v-text-field>
                            </v-col>
                            <v-col cols="2">
                                <v-btn color="primary"
                                       @click="load_cluster()"
                                       class="mt-3"
                                       x-small>Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-alert v-if="iperror" color="yellow">
                    {{iperror}}
                </v-alert>

                <v-card class="mb-5" v-if="cluster_history.length > 0">
                    <v-card-title>
                        Behavioural IP Cluster #{{cluster}} timeline graph:
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12">
                                <line-chart
                                    adapter="highcharts"
                                    :data="cluster_history"
                                    ytitle="IP cluster member number"
                                    :curve="false"
                                    height="800px"
                                >
                                </line-chart>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-col cols="3" v-if="cluster_ips.length > 0">
                    <h3>Membership for Behavioural Cluster #{{cluster}}</h3>
                    <ul>
                        <li v-for="ip in cluster_ips"
                            :key="ip[0]">
                            <a :href="ip[1]">{{ip[0]}}</a>
                        </li>
                    </ul>
                </v-col>
            </v-col>
        </v-row>
    </v-container>
 </template>

 <script>
  import Vue from 'vue';
  import Chartkick from 'vue-chartkick';
  import Highcharts from 'highcharts';
  import LoginRequired from '../components/LoginRequired';
  import { get_url_value, set_url_value } from '../utils.js'

  Vue.use(Chartkick.use(Highcharts))

  import { fetch_one } from "../utils.js";
  var ipcluster_data = {
      cluster: "",
      cluster_history: {},
      cluster_search: "",
      cluster_ips: [],
      iperror: '',
  }

  var load_cluster = async function() {
      var clusternum  = ipcluster_data['cluster_search']
      if (! clusternum) {
          ipcluster_data["cluster_history"] = {};
          ipcluster_data["cluster"] = undefined;
          return;
      }

      var cluster_history = 
          await fetch_one("/enrich/haas/cluster-history/" + clusternum);
      if (cluster_history && cluster_history.timeline.length > 0) {

          ipcluster_data["cluster_history"] = {};
          var address_cache = {};

          var address_counter = 1;
          cluster_history.timeline.forEach(function(value) {
              var epoch = value[0];
              var ip = value[1]
              if (! Object.keys(address_cache).includes(ip)) {
                  address_cache[ip] = {'name': ip,
                                       'data': {},
                                       'counter': address_counter};
                  address_counter = address_counter + 1;
              }
              var timestamp = new Date(epoch * 1000);
              address_cache[ip]['data'][timestamp] = address_cache[ip]['counter'];
          })          

          ipcluster_data["cluster_history"] = Object.values(address_cache);
          console.log(ipcluster_data["cluster_history"]);
          ipcluster_data["cluster"] = clusternum
      }

      var cluster_ips =
          await fetch_one("/enrich/haas/cluster-ips/" + clusternum);
      if (cluster_ips && cluster_ips.ips.length > 0) {
          ipcluster_data["cluster_ips"] = [];
          cluster_ips.ips.forEach(function(value) {
              var link = "/#/?tab=ipsearch&ipcluster=" + clusternum +
                         "&ipaddress=" + value[0];
              ipcluster_data["cluster_ips"].push([value[0],
                                                  link]);
          })
      }
  }

  export default {
      name: 'IPCluster',
      data: function() { return ipcluster_data; },
      props: ['user'],
      created() {
          var cluster_search = get_url_value(this, 'ipcluster');
          ipcluster_data["cluster_search"] = cluster_search;
      },
      methods: {
          'load_cluster': load_cluster,
      },
      watch: {
          "cluster_search": function() {
              set_url_value(this, 'ipcluster',
                            ipcluster_data['cluster_search']);
              load_cluster(ipcluster_data['cluster_search'])
          }
      },
      components: {
         LoginRequired,
      },
  };
 </script>

 <style>
 </style>
