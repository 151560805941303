<template>
    <v-container fluid>
        <v-card>
            <v-card-title>
                <h2>Similar Command / TTP Explorer</h2>
            </v-card-title>
            <v-card-text>
                <div id='scrollable'>
                    <div class="chartArea" id="chart">chart ...</div>
                </div>
            </v-card-text>
        </v-card>
        <v-card v-if="newips.length == 0" class="mt-2">
            <v-card-title>Graph Navigator</v-card-title>
            <v-card-text>
                <h3>Usage Instructions</h3>
                <ol>
                    <li>Click on a graph point to start exploring. <br />
                        <em>Note: clicks must be very precise</em></li>
                    <li>The small numbers indicate the number of new
                        command templates seen at that time point.</li>
                    <li>When hovering, a <font color="red">red x</font>
                        indicates a new command was seen during that time period</li>
                    <li>After clicking on a point, details of all of
                        the data for that time period will be displayed
                        below the graph.</li>
                </ol>
            </v-card-text>
        </v-card>

        <v-overlay :value="command_dialog" width="500" height="500" opacity=".9">
            <h3>graph for template #{{current_command}}
                <v-btn color="primary" @click="command_dialog = false" class="float-right" x-small>Close</v-btn>
            </h3>
            <div id="commandCountsChart" style="width:800px; height:300px;">
                <div id="cmdChart" style="height: 200px; width: 100%;" class="scrolled"></div>
                <div id="ipsContainer" style="height: 200px; overflow-y: scroll">
                    <h3>IP Addresses</h3>
                </div>
            </div>
        </v-overlay>


        <span v-if="newips.length > 0">
            <v-card class="mt-2">
                <v-card-title>
                    <h2>Command templates detected on: {{fulldate}}</h2>
                </v-card-title>
                <v-card-text>
                    <v-row v-if="newips.length > 0" >
                        <v-col cols="4">
                            <v-card>
                                <v-card-title>
                                    New IP addresses: {{newips_mapped.length}}
                                </v-card-title>
                                <v-card-text>
                                    <v-data-table dense
                                                  :items="newips_mapped"
                                                  :headers="newips_headers"
                                                  sort-by="ip">
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4">
                            <v-card class="scrolled" height="650px">
                                <v-card-title>Techniques seen: {{command_summary.length}}</v-card-title>
                                <v-card-text>
                                    <!-- <div id="commandCountsChart" style="width:800px; height:300px;"></div> -->
                                    <v-simple-table dense>
                                        <tr>
                                            <th class="text-left" width="20%">Command template</th>
                                            <th class="text-left">Count</th>
                                        </tr>
                                        <tr v-for="(summary, index) in command_summary"
                                            :key="`summary-${index}`">
                                            <td>
                                                <a href="#" @click="$vuetify.goTo('#detail' + index)">
                                                    {{index}}
                                                </a>
                                            </td><td>{{summary}}</td>
                                        </tr>
                                    </v-simple-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col cols="4" v-if="newcommands.length > 0">
                            <v-card class="scrolled">
                                <v-card-title>New techniques: {{newcommands.length}}</v-card-title>
                                <v-card-text>
                                    <ul>
                                        <li v-for="(line, index) in newcommands"
                                            :key="`newcmd-${index}`">
                                            <a href="#" @click="$vuetify.goTo('#detail' + line)">{{line}}
                                            </a>
                                        </li>
                                    </ul>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        <v-card class="mt-2">
                <v-card-title>
                    Technique Details
                </v-card-title>
                <v-card-text>

                    <v-expansion-panels>
                    <v-expansion-panel v-for="(session, key) in details"
                            :key="key"
                            :id="`detail${key}`"
                            class="mt-2">
                        <v-expansion-panel-header>
                            <v-row>
                                <v-col cols="4">Command template: {{key}}</v-col><v-spacer />
                                <v-col cols="3">Commands: {{session['cmds'].length}}</v-col>
                                <v-col cols="3">IPs: {{session['ips'].length}}</v-col>
                                <v-col cols="2">
                                    <v-chip
                                        v-if="session.new == 1"
                                        x-small
                                        color="red"
                                        class="mx-10"
                                        text-color="white">NEW</v-chip>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <h3>IPs
                                <v-btn @click="show_command_graph(key)" class="mx-10 float-right" x-small>
                                    Graph
                                </v-btn>
                            </h3>
                            <v-simple-table dense class="scrolled">
                                <tr>
                                    <th class="text-left" width="20%">IP</th>
                                    <th class="text-left">Session Timestamp</th></tr>
                                <tr v-for="(ip, index) in session['ips']"
                                    :key="`ip-${index}`">
                                    <td>{{ip[0]}}</td>
                                    <td>{{our_date_format(ip[1])}}</td>
                                </tr>
                            </v-simple-table>
                            <h3 class="mt-5">Sample Commands</h3>
                            <v-simple-table class="scrolled">
                                <tr>
                                    <th class="text-left">IP Address</th>
                                    <th class="text-left">Date</th>
                                    <!-- <th class="text-left">Length</th> -->
                                    <th class="text-left">Command</th>
                                </tr>
                                <tr v-for="(cmd, index) in session['cmds']"
                                    :key="`cmd-${index}`">
                                    <td width="15%">{{cmd[0]}}</td>
                                    <td width="25%">{{our_date_format(cmd[1])}}</td>
                                    <!-- <td width="10%">{{cmd[2]}}</td> -->
                                    <td width="60%">
                                        <pre background-color="white"
                                             overflow-x="scroll">{{cmd[3].trim()}}
                                        </pre>
                                    </td>
                                </tr>
                            </v-simple-table>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    </v-expansion-panels>
                    <hr />

                    
                </v-card-text>
            </v-card>
        </span>
    </v-container>
</template>

<script>
 import { fetch_one } from "../utils.js";
 import $ from 'jquery';
 import jquery from 'jquery';
 import CanvasJS from './canvasjs.min.js';
 import dateFormat from 'dateformat';

 var Mychart = window.CanvasJS.Chart
 var bogus = jquery
 bogus = $
 bogus = CanvasJS
 bogus = ""
 console.log(bogus) // squash elint

 var our_date_format = function(epoch) {
     return dateFormat(epoch * 1000, "yyyy-mm-dd hh:MM")
 }

 var display_data = {
     'fulldate': "click on a point",
     'command_summary': [],
     'newcommands': [],
     'newips': [],
     'newips_mapped': [],
     'details': [],
     'sessions': {},
     'currentpoint': 0,
     'our_date_format': our_date_format,
     'command_dialog': false,
     'current_command': 0,
     'ip_headers': [
         {'text': 'IP Address', 'value': 'line', sortable: true},
     ],
     'session_headers': [
         {'text': "IP Address", 'value': 0, sortable: true},
         {'text': "Timestamp", 'value': 1, sortable: true},
     ],
     'newips_headers': [
         {'text': "IP Address", 'value': 'ip', sortable: true},
     ],
 }

 var STPL = {
     SESSIONS_BY_DAY: {},
     BY_CMD: {},
     INDEX: {}
 };


var cmdDetailsBtn;
var click_graph = function(cmdClass2) {
     console.log('Getting details for ', cmdClass2);
     getCommandDetails(cmdClass2, function(details) {
         console.log('Details are ', details);
         // Prepare chart.
         var dataPoints = details.histogram.map(point => Object({x: new Date(point[0]*1000), y: point[1]}));

         console.log(cmdDetailsBtn); // says unused
         var ipsContainer = $('#ipsContainer')[0];
         for (var ipAddress in details.ips) {
             var count = details.ips[ipAddress];
             $('<span class="greyfloat">').html(ipAddress + ' (' + count + ')').appendTo(ipsContainer);
             //console.log(ipAddress)
         }
         var chartData = {        
             type: "area",
             indexLabelFontSize: 12,
             dataPoints: dataPoints,
         };
         var chart = new Mychart($("#cmdChart")[0], {
             axisX:{gridThickness: 0.5},
             axisY:{gridThickness: 0.5},
             data: [chartData],
             toolTip:{                              
                 content: function(e) {                      
                     var dataPoint = e.entries[0].dataPoint;            
                     return dataPoint.x.toString().split(' (')[0] + ' : <span style="color: red;">' + dataPoint.y + '</span> ';
                 }
             },
         });
         
         setTimeout(function() {
             chart.render();
         }, 100);
         
     });
 }










 var getCommandDetails = function(cmdClass, callback) {
     if (STPL.BY_CMD[cmdClass]) {
         callback(STPL.BY_CMD[cmdClass]);
     } else {
         $.get('/data/bycmd/c' + cmdClass + '.json', function(response) {
             STPL.BY_CMD[cmdClass] = response;
             callback(response);
         });
     }
 };


 var getSessions = function(secondsSinceEpoch, callback) {
     const secondsInDay = 24 * 60 * 60;
     var dayTimestamp = secondsSinceEpoch - (secondsSinceEpoch % secondsInDay);
     if (STPL.SESSIONS_BY_DAY[dayTimestamp]) {
         var dayData = STPL.SESSIONS_BY_DAY[dayTimestamp];
         callback(dayData[secondsSinceEpoch]);
     } else {
         $.get('/data/days/' + dayTimestamp + '.json', function(dayData) {
             STPL.SESSIONS_BY_DAY[dayTimestamp] = dayData;
             callback(dayData[secondsSinceEpoch]);
         });
     }
 };

 //var INFINITE_PALLET = {'next': 0, 'colors': 'Red', }

 var displayChart = function () {
     var chartArea = document.getElementsByClassName("chartArea")[0];

     var dataPoints = STPL.INDEX.histogram.map(function(point) {
         var timestamp = point[0];
         var count = point[1];

         var jsDay = new Date(timestamp * 1000);
         var datapoint = {x: jsDay, y: count};
         //var newIps = 0;
         var newCmds = 0;  // Command classes
         /* if (STPL.INDEX.ip_appearance[timestamp]) {
          *     newIps = STPL.INDEX.ip_appearance[timestamp].length;
          * } */
         if (STPL.INDEX.cmd_appearance[timestamp]) {
             newCmds = STPL.INDEX.cmd_appearance[timestamp].length;
         }
         //if (newCmds + newIps > 0) {
         if (newCmds > 0) {
             /*
                var labelParts = [];
                if (newCmds) {
                labelParts.push(newCmds + ' cmds');
                }
                if (newIps) {
                labelParts.push(newIps + ' IPs');
                }
                var label = 'new: ' + (labelParts.join(','));
              */
             datapoint.indexLabel = '' + newCmds;
             datapoint.indexLabelColor = 'Red';
             datapoint.markerColor = "Red";
             datapoint.markerType = "cross";
         }

         return datapoint;
     });
     var onChartPointClick = function(e){
         var secondsSinceEpoch = e.dataPoint.x/1000;
         var command2count = STPL.INDEX.time2command[secondsSinceEpoch];

         // Populate summary <pre>
         var lines = [];
         var barDataPoints = [];
         for (var cmd in command2count) {
             lines.push(cmd + '\t' + command2count[cmd]);
             barDataPoints.push({y: command2count[cmd], label:'cmd ' + cmd});
         }
         display_data['command_summary'] = command2count


         /* var chart2 = new Mychart($("#commandCountsChart")[0], {        
          *     axisX:{                                  
          *         interval: 1                               
          *     },                                     
          *     animationEnabled: true,                          
          *     data: [{                                  
          *         axisYType: "secondary",                         
          *         dataPoints: barDataPoints,                        
          *         color: "#014D65",                            
          *         type: "bar",                               
          *         click: function(e) {                           
          *             $([document.documentElement, document.body]).animate({         
          *                 scrollTop: $("#cmdH2_" + e.dataPoint.label.split(' ')[1]).offset().top
          *             }, 200);                                
          *         }
          *     }]                                     
          * });                                     
          * chart2.render();
          */

         // Populate new things
         var newIps = new Set([]);
         var newCmds = new Set([]);  // Command classes
         if (STPL.INDEX.cmd_appearance[secondsSinceEpoch]) {
             newCmds = new Set(STPL.INDEX.cmd_appearance[secondsSinceEpoch]);
             display_data['newcommands'] = STPL.INDEX.cmd_appearance[secondsSinceEpoch]
         }
         if (STPL.INDEX.ip_appearance[secondsSinceEpoch]) {
             newIps = new Set(STPL.INDEX.ip_appearance[secondsSinceEpoch]);
             display_data['newips'] = STPL.INDEX.ip_appearance[secondsSinceEpoch]
             console.log("new ips:")
             console.log(display_data['newips'])
             display_data['newips_mapped'] = []
             display_data['newips'].forEach(function(item) {
                 display_data['newips_mapped'].push({'ip': item});
             });
             console.log(display_data['newips_mapped']);
         }
         
         
         // Populate details
         $('#details').empty();
         getSessions(secondsSinceEpoch, function(sessions) {
             console.log('got sessions', sessions);
             display_data['details'] = sessions
             display_data['currentpoint'] = secondsSinceEpoch
             for (var cmdClass in sessions) {
                 var cmdH2 = $('<h2>').html('Command class: ' + cmdClass).appendTo($('#details'));
                 if (newCmds.has(Number(cmdClass))) {
                     cmdH2.append($('<span class=redText> (NEW)</span>'));
                     sessions[cmdClass]['new'] = 1;
                 } else {
                     sessions[cmdClass]['new'] = 0;
                 }

                 var cmdDetailsBtn = $('<button>').html('...').appendTo(cmdH2).click(function(cmdClass2) {
                     console.log('Getting details for ', cmdClass2);
                     getCommandDetails(cmdClass2, function(details) {
                         console.log('Details are ', details);
                         // Prepare chart.
                         var dataPoints = details.histogram.map(point => Object({x: new Date(point[0]*1000), y: point[1]}));

                         var popupDiv = $(`
            <div>
              <div id="cmdChart" style="height: 200px; width:1000px"></div>
              <div id="ipsContainer" style="height: 200px; overflow-y: scroll">
                <h3>IP Addresses</h3>
              </div>
            </div>
                         `);
                         console.log(cmdDetailsBtn); // says unused
                         var ipsContainer = popupDiv.find('#ipsContainer');
                         for (var ipAddress in details.ips) {
                             var count = details.ips[ipAddress];
                             $('<span class="greyfloat">').html(ipAddress + ' (' + count + ')').appendTo(ipsContainer);
                             //console.log(ipAddress)
                         }
                         var chartData = {        
                             type: "area",
                             indexLabelFontSize: 12,
                             dataPoints: dataPoints,
                         };
                         var chart = new Mychart(popupDiv.find("#cmdChart")[0], {
                             axisX:{gridThickness: 0.5},
                             axisY:{gridThickness: 0.5},
                             data: [chartData],
                             toolTip:{                              
                                 content: function(e) {                      
                                     var dataPoint = e.entries[0].dataPoint;            
                                     return dataPoint.x.toString().split(' (')[0] + ' : <span style="color: red;">' + dataPoint.y + '</span> ';
                                 }
                             },
                         });
                         
                         popupDiv.dialog({width: 1100, height:500});
                         setTimeout(function() {
                             chart.render();
                         }, 100);
                         
                     });
                 }.bind(window, cmdClass))
                 var ipsAndTimestamps = sessions[cmdClass]['ips']; 
                 var ipTimestampDurationCommandList = sessions[cmdClass]['cmds'];

                 // COnnected IPs
                 var ipsDiv = $('<div>').appendTo($('#details'));
                 ipsDiv.append($('<h3>IPs</h3>'));
                 ipsAndTimestamps.forEach(function(ipAndTimestamp) {
                     var span = $('<span class="greyfloat"><span id="ip"></span> @ <span id="time"></span></span>').appendTo(ipsDiv);
                     span.find('#ip').html(ipAndTimestamp[0]);
                     span.find('#time').html(Math.floor(ipAndTimestamp[1] - secondsSinceEpoch));
                     if (newIps.has(ipAndTimestamp[0])) {
                         span.find('#ip').append($('<span class=redText> (NEW)</span>'));
                     }
                 });

                 var sampleCommandsDiv = $('<div>').appendTo($('#details'));
                 sampleCommandsDiv.append($('<h3>Sample Commands</h3>'));
                 ipTimestampDurationCommandList.forEach(function(ipTimestampDurationCommand) {
                     sampleCommandsDiv.append($('<hr>'));
                     var headlinedCommand = (
                         '# ' + ipTimestampDurationCommand[0] + ' @ ' + ipTimestampDurationCommand[1] +
                         ' (session duration: ' + ipTimestampDurationCommand[2] + ') executed:\n' + ipTimestampDurationCommand[3]);
                     $('<pre>').html(headlinedCommand).appendTo(sampleCommandsDiv);
                 });
             }

         });
     };
     var chartData = {        
         type: "area",
         indexLabelFontSize: 12,
         dataPoints: dataPoints,
         click: onChartPointClick
     };
     var chart = new Mychart(chartArea, {
         axisX:{gridThickness: 0.5},
         axisY:{gridThickness: 0.5},
         data: [chartData],
         toolTip:{                              
             content: function(e) {                      
                 var dataPoint = e.entries[0].dataPoint;            
                 return dataPoint.x.toString().split(' (')[0] + ' : <span style="color: red;">' + dataPoint.y + '</span> ';
             }
         },
     });
     chart.render();
 };

 var load_chart = async function() {
     STPL.INDEX = await fetch_one("/data/cowrie_timeseries.json");
     displayChart();
     console.log("loaded chart data");
 }

 export default {
     name: 'CommandSeries',
     data: function() { return display_data },
     created() { load_chart(); },
     watch: {
         'currentpoint': function() {
             display_data['fulldate'] = our_date_format(display_data['currentpoint']);
         }
     },
     methods: {
         show_command_graph(num) {
             if (num > -1) {
                 display_data['command_dialog'] = true;
                 display_data['current_command'] = num;
                 click_graph(num);
                 console.log("showing graph: " + num);
             }
         }
     }
     
 }

</script>

<style scoped>
 div.chartArea {
     height: 200px;
     width: 5000px;
 }
 pre {
     margin-bottom: 0;
     width: 1000px;
     white-space: break-spaces;
     background-color: #ffffff;
 }
 .redText {
     color: #ee5050;
 }
 span.greyfloat {
     padding: 5px;
     margin:2px;
     white-space: nowrap;
     background-color: #f0f0f0;
 }

 div#scrollable {
     width: 1000px;
     overflow-x: scroll;
 }

 td {
     padding-left: 5px;
     padding-right: 5px;
 }

 .scrolled {
     overflow-y: auto;
     max-height: 20em;
 }

</style>
