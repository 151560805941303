<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <p>
                    These tabs describe the malware collected by the GAWSEED project and distributed via the GiFT.
                </p>

                <v-card>
                    <v-card-title>Summary Statistics</v-card-title>
                    <v-card-text>
                        <v-simple-table dense>
                            <tbody>
                                <tr><th>Total files collected</th>
                                    <td>{{information.compared}}</td></tr>
                                <tr><th class="pl-10">Seen in GiFT  first</th>
                                    <td class="pl-10">{{information.gift_first}} ({{information.gift_first_percent * 100}}%)</td></tr>
                                <tr><th class="pl-10">Only in GiFT</TH>
                                    <td class="pl-10">{{information.gift_only}} ({{information.gift_only_percent * 100}}%)</td></tr>


                                <tr><th>Files in both GiFT and VirusTotal</th>
                                    <td>{{precision.seen_both}}</td></tr>
                                <tr><th class="pl-10">Median days seen ahead of VirusTotal</th>
                                    <td class="pl-10">{{information.median_first}} days</td></tr>
                                <tr><th class="pl-10">GiFT false positives</th>
                                    <td class="pl-10">{{precision.gift_false_positives}}</td></tr>
                                <tr><th class="pl-10">Precision</th>
                                    <td class="pl-10">{{precision.gift_precision}}</td></tr>
                            </tbody>
                        </v-simple-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import { fetch_one } from "../utils.js";

 var malware_data = {
     'date': "unknown",
     'information': {'compared': 0},
     'precision': {}
 }

 export default {
     name: 'MalwareSummary',
     data: function() { return malware_data; },
     props: [],
     created() { fetch_malware_info() },
 }

 var fetch_malware_info = async function() {
     try {
         var data = await fetch_one("/data/malware-stats.json");

         malware_data['date'] = data['date']
         malware_data['information'] = data;
         console.log("loaded malware stats")
         console.log(malware_data['information'])
     } catch (error) {
         console.log("failed to load malware stats")
     }

     try {
         data = await fetch_one("/data/binary-precision-stats.json");

         malware_data['precision'] = data;
         console.log("loaded binary precision stats")
     } catch (error) {
         console.log("failed to load malware data")
     }
 }


</script>
