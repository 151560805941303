<template>
  <v-app>
      <router-view></router-view>
      <v-main>
          <v-container>
              <v-tabs v-model="tab">
                  <v-tab href="#about">About</v-tab>
                  <v-tab-item value="about">
                      <About :user="user" />
                  </v-tab-item>
                  
                  <v-tab href="#stats">Feed Detailed Statistics</v-tab>
                  <v-tab-item value="stats">
                      <GiftStats/>
                  </v-tab-item>

                  <v-tab href="#descriptions">Feed Descriptions</v-tab>
                  <v-tab-item value="descriptions">
                      <Descriptions/>
                  </v-tab-item>

                  <v-tab href="#ipsearch">IP Search</v-tab>
                  <v-tab-item value="ipsearch">
                      <IPSearch :user="user" />
                  </v-tab-item>

                  <v-tab href="#ipcluster">IP Cluster Anaylsis</v-tab>
                  <v-tab-item value="ipcluster">
                      <IPCluster :user="user" />
                  </v-tab-item>
              </v-tabs>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>
 import GiftStats from '../components/GiftStats';
 import About from '../components/About';
 import Descriptions from '../components/Descriptions';
 import IPSearch from '../components/IPSearch';
 import IPCluster from '../components/IPCluster';
 
 var gift_data = {
     main_tabs: "0",
 }

 export default {
     name: 'GiFT',
     
     props: ['user'],
     components: {
         GiftStats,
         About,
         Descriptions,
         IPSearch,
         IPCluster,
     },
     
     data: function () { return gift_data },

     computed: {
         tab: {
             set(tab) {
                 this.$router.replace({ query: { ...this.$route.query, tab}})
             },
             get() {
                 return this.$route.query.tab
             },
         },
     },
 };
</script>
<style>
 nav router-link-exact-active,
 nav router-link-active {
     color: "red";
     background-color: "indianred";
 }
 .v-btn {
     color: "red";
 }
</style>
