<template>
    <v-container>
        <p><b>Last updated: {{global_summary['date']}}</b></p>
        <v-expansion-panels
            v-model="panel">
            <v-expansion-panel
                v-for="(name, index) in summary_keys"
                :key="index"
                :value="index"
                :id="index">
                <v-expansion-panel-header>
                    Recent {{name}}: {{global_summary[index]}}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                    <line-chart
                        adapter="highcharts"
                        :data="datasets[index]"
                        :ytitle="name"
                        :curve="false"
                    ></line-chart>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-container>
</template>

<script>
 import Vue from 'vue';
 import Chartkick from 'vue-chartkick';
 import Highcharts from 'highcharts';
 import { fetch_one } from "../utils.js";

 Vue.use(Chartkick.use(Highcharts))

 var data = {
     'panel': 0,
     'global_summary': {},
     'datasets': {
         'gift_unique_ips':  [],
         'gift_safe_scanner_ips':  [],
         'gift_malware':  [],
     },
     'summary_keys': {
         'gift_unique_ips': 'Malicious IP Addresses',
         'gift_safe_scanner_ips': 'Known Research Scanner IPs',
         'gift_malware': 'Malware',
     }
 };

 var fetch_unique_ips = async function() {
     var response = await fetch_one("/data/gift_unique_ips.json");
     data['datasets']['gift_unique_ips'] = {};
     response.forEach(function(item) {
         data['datasets']['gift_unique_ips'][item.date] = [item.value];
     })
 }

 var fetch_safe_scanner_ips = async function() {
     var response = await fetch_one("/data/gift_safe_scanner_ips.json");
     data['datasets']['gift_safe_scanner_ips'] = {};
     response.forEach(function(item) {
         data['datasets']['gift_safe_scanner_ips'][item.date] = [item.value];
     })
 }

 var fetch_malware = async function() {
     var response = await fetch_one("/data/gift_malware.json");
     data['datasets']['gift_malware'] = {};
     response.forEach(function(item) {
         data['datasets']['gift_malware'][item.date] = [item.value];
     })
 }

 var fetch_summary = async function() {
     var response = await fetch_one("/data/global-summary.json");
     data['global_summary'] = response;
     var date = new Date(response['time'] * 1000);
     data['global_summary']['date'] =
         date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate();
 }

 export default {
     name: 'FeedGeneralStatistics',
     data: function() {
         return data;
     },
     created: function() {
         fetch_summary();
         fetch_unique_ips();
         fetch_safe_scanner_ips();
         fetch_malware();
     },
     props: [],
 }
</script>
