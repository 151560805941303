<!--
 (auto-save-buffers-enhanced-toggle-activity) 
 (setq create-lockfiles nil)
-->
<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card class="mb-3">
                    <v-card-title>
                        <h2>Temporal Honeypot Analysis</h2>
                    </v-card-title>
                    <v-card-text>
                        <p>
                            Below is a graphical representation of
                            commands being executed between two weeks
                            of honeypot data.  The red nodes are newly
                            discovered commands seen within the last
                            week, but not the week before.  The
                            distance between the nodes represents the
                            similarity of the commands, where commands
                            closer to gether are determined to be more
                            similar.
                        </p>

                        <v-select label="Date"
                                  v-model="date"
                                  :items="date_list">
                        </v-select>
                        <v-img :src="image_path"
                               contain>
                        </v-img>
                    </v-card-text>
                </v-card>
                <v-card class="mb-3">
                    <v-card-title>
                        Newly discovered command samples for the week ending {{date}}
                    </v-card-title>
                    <v-card-text>
                        <p>This table shows example commands newly found in the last week (ending near {{date}}) represented by the numbers in the red circles in the graph above. Note that commands may vary significantly with different parameters, but have been clustered together to appear as a single node using GAWSEED algorithms.</p>
                        <v-data-table
                            :items="this_clusterlist"
                            :headers="clusterlist_headers"
                            :sort-by="['node']"
                        ></v-data-table>
                    </v-card-text>
                </v-card>
                <v-card class="mb-3">
                    <v-card-title>
                        Previously know command samples
                    </v-card-title>
                    <v-card-text>
                        <p>This table shows example commands that existing in the previous week, represented by the numbers in the circles in the blue graph above.</p>
                        <v-data-table
                            :items="last_clusterlist"
                            :headers="clusterlist_headers"
                            :sort-by="['node']"
                        ></v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import { fetch_one } from "../utils.js";
 import { get_url_value, set_url_value } from '../utils.js'

 var temporalhoneypot_data = {
     'date': "recent",
     'image_path': '/data/honeypot-distance-graph/recent-distancegraph.png',
     'date_list': ['recent'],
     'this_clusterlist': [],
     'last_clusterlist': [],
     'clusterlist_headers': [
         {'text': 'Cluster Id', 'value': 'node', sortable: true},
         {'text': 'Command', 'value': 'command', sortable: true},
     ],
 }

 var fetch_date_list = async function() {
     var date_list = await fetch_one('/data/honeypot-distance-graph/date-list-timegraphs.json');
     date_list.unshift('recent');
     temporalhoneypot_data['date_list'] = date_list;
     console.log("loaded date list:");
     console.log(date_list);
 }

 var fetch_data_for_date = async function(date) {
     console.log("initial date: " + date);
     if (!date) {
         date = temporalhoneypot_data['date']
     }

     console.log("initial2 date: " + date);
     if (date === "recent") {
         console.log(temporalhoneypot_data['date_list']);
         // pull out the most recent date to use

         if (temporalhoneypot_data['date_list'].length == 1) {
             await fetch_date_list();
         }

         date = temporalhoneypot_data['date_list'][1];
     }

     console.log("fetching data for " + date);
     var this_clusterlist = await fetch_one('/data/honeypot-distance-graph/' + date +
                                       '-temporal-thisweek.json');
     var last_clusterlist = await fetch_one('/data/honeypot-distance-graph/' + date +
                                            '-temporal-lastweek.json');
     console.log("assigning");
     console.log(this_clusterlist);
     console.log(last_clusterlist);
     temporalhoneypot_data['this_clusterlist'] = this_clusterlist;
     temporalhoneypot_data['last_clusterlist'] = last_clusterlist;
     temporalhoneypot_data['image_path'] = '/data/honeypot-distance-graph/' +
                                           date + '-timegraph.png';
     console.log(temporalhoneypot_data['clusterlist']);
 }

 export default {
     name: 'TimeMotifReport',
     data: function() { return temporalhoneypot_data; },
     created() {
         temporalhoneypot_data['date'] = get_url_value(this, 'date', 'recent');
         fetch_data_for_date();
     },
     watch: {
         'date': function() {
             set_url_value(this, 'date', temporalhoneypot_data['date']);
             fetch_data_for_date();
         }
     }
 }
</script>

<style>
    .corrsection {
        background-color: "#ffffff";
    }
</style>
