<template>
  <v-app>
      <v-main>
          <router-view></router-view>
          <v-container fluid>
              <!-- <v-banner>
                   <h3>Explore</h3>
                   </v-banner> -->
              <CommandSeries/>
          </v-container>
      </v-main>
  </v-app>
</template>

<script>
 import CommandSeries from '../components/CommandSeries';
 
 var explore_data = {
 }

 export default {
     name: 'Explore',
     
     components: {
         CommandSeries,
     },
     
     data: function() { return explore_data; },

     created() { },

     computed: {
         tab: {
             set(tab) {
                 this.$router.replace({ query: { ...this.$route.query, tab}})
             },
             get() {
                 return this.$route.query.tab
             }
         }
     },
 };
</script>
