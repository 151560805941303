<template>
    <v-container>
        <v-row>
            <v-col cols="12" class="mt-2">
                <h3>Collected MIME types</h3>

                <p>Each of the files collected in GiFT, regardless of
                whether they have been identified by VirusTotal or not
                as malware, are analyzed for their mime, as reported
                in the bar charts below.</p>

                <a href="/binaries/images/binary-types-sum.png"><v-img src="/binaries/images/binary-types-sum.png" contain max-width="80%" /></a>

                <a href="/binaries/images/binary-types-sum-zoomed.png"><v-img src="/binaries/images/binary-types-sum-zoomed.png" contain max-width="80%" /></a>

                <h3>Binary executable architectures</h3>

                <p>Binary executables (the first column in the previous graph) are
                    further broken down by the individual processing
                    architectures in the following graph.</p>
                <a href="/binaries/images/binary-types-arches.png"><v-img src="/binaries/images/binary-types-arches.png" contain max-width="80%" /></a>
                
                <!-- no longer producing these
                <h3>Breakdown by Malware Identification Engine tags</h3>
                <p>The following bar chart shows the tags associated
                    with each file we've collected that also appears in
                    Virus Total.  Note that files have multiple tagswe'col
                    associated with them, so the total count will appear
                    higher than the total number of files collected within the GiFT.</p>
                <a href="/binaries/images/vt-tags.png"><v-img src="/binaries/images/vt-tags.png" contain max-width="80%" /></a>
                -->
                <h3>Breakdown of files unique to GiFT</h3>
                <p>Since GiFT collects a large number of files that
                    have not yet or will never appear in Virus Total,
                    we analyze them independently for their mime
                    types.  Note that because the collection of <em>text/html</em> types,
                    which mostly stems from web site "take down" notices where malware
                    has been removed from a site.</p>
                <a href="/binaries/images/binary-types-gift-only.png"><v-img src="/binaries/images/binary-types-gift-only.png" contain max-width="80%" /></a>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 export default {
     name: 'MalwareArches',
     data: function() { return {}; },
     props: [],
     created() { },
 }

</script>
