import dateformat from 'dateformat';

export async function fetch_one(url, dest, datamap) {
    try {
        console.log("fetching: " + url)
        var response =
            await fetch(url);
        var data = {'status': 'fail', 'message': {}};
        if (response.ok){
            data = await response.json();
        } else {
            console.log("    failed to fetch: " + response.status)
        }

        if (dest && datamap) {
            // console.log("loaded into: " + dest)
            datamap[dest] = data;
        }

        return data

    } catch (error) {
        console.log("failed to load " + url);
        console.log(error);
        console.log(response);
    }
}

export function get_recent_date(daysback) {
    var timestamp = new Date().getTime()
    timestamp -= daysback * 86400 * 1000; /* start 3 days back */
    return dateformat(timestamp, 'yyyy-mm-dd')
}

export function get_last_thirty_days() {
    var date_list = ['recent']
    var startdate = new Date().getTime()
    startdate -= 3 * 86400 * 1000; /* start 3 days back */
    for (var i = 0 ; i < 30 ; i++ ) {
        date_list.push(dateformat(startdate, 'yyyy-mm-dd'))
        startdate -= 86400 * 1000;
    }
    return date_list;
}

export function round_number(number) {
    var tmp = +(number)                        // cast to float
    tmp = "" + tmp.toPrecision(5);             // use precision of 3
    // drop any extra output from rounding errors
    tmp = tmp.replace(/([0-9]*\.[0-9]?[0-9]?[0-9]?).*/,"$1");
    return tmp;
}

export function round_rows(rows, attributes) {
    for (var i = 0 ; i < rows.length; i++) {
        for (var j = 0; j < attributes.length; j++) {
            rows[i][attributes[j]] = round_number(rows[i][attributes[j]]);
        }
    }
    return rows;
}

export function percent_rows(rows, attributes) {
    for (var i = 0 ; i < rows.length; i++) {
        for (var j = 0; j < attributes.length; j++) {
            rows[i][attributes[j]] = 100.0 * rows[i][attributes[j]];
        }
    }
    return rows;
}

export function round_dict(rows, attributes) {
    for(const item in rows) {
        for (var j = 0; j < attributes.length; j++) {
            rows[item][attributes[j]] = round_number(rows[item][attributes[j]]);
        }
    }
    return rows;
}

export async function fetch_global_counters() {
    var data;
    var counter_data = {};
    
    // fetch TTP correlations
    try {
        data = await fetch_one("/data/correlations.json")
        var count = 0;
        data['correlations'].forEach(function(item) {
            if (item['is_new'] === "1") {
                count++;
            }
        });
        counter_data['new_ttp_count'] = count;
        counter_data['correlation_data'] = data
    } catch (error) { console.log("failed correlations sum"); }

    // get port rising numbers
    try {
         data = await fetch_one("/api/fetch/port/recent");
         counter_data['total_new_diff'] = data['message']['total_new_diff'];
         counter_data['total_new_percent'] = data['message']['total_new_percent'];

         counter_data['new_service_count'] =
             data['message']['total_new_diff'] + data['message']['total_new_percent'];

        counter_data['service_data'] = data
    } catch (error) { console.log("failed service summary"); }

    // get URI rising numbers
    try {
        data = await fetch_one("/data/http-method-rising.json")
        counter_data['new_uri_count'] = data.length
    } catch (error) { console.log("failed service sum"); }
    console.log(counter_data)

    counter_data['all_counters'] =
        counter_data['new_uri_count'] +
        counter_data['new_service_count'] +
        counter_data['new_ttp_count'];

    return counter_data;
}

export function get_day_before(thisdate) {
    var d = Date.parse(thisdate);
    d = d - 86400;
    return dateformat(d, 'yyyy-mm-dd')
}

// functions for storing and retrieving URL components better
export function get_url_value(parent, name, optional_default) {
    var value = parent.$route.query[name];

    if (value === undefined && optional_default) {
        value = optional_default;
    }

    console.log("GET URL: " + name + " / value: " + value);
    // console.log(parent.$route.query);
    // console.log(parent.$route)
    return value;
}

export function set_url_value(parent, name, value, clear_all) {
    if (!parent || !('$route' in parent)) {
        console.log("unable to see route in parent");
        console.log(parent);
        return;
    }

    if (parent.$route.query[name] === value) {
        return; // no change
    }

    var newquery;
    if (clear_all) {
        // keep just the tab and date values
        newquery = {'tab': parent.$route.query['tab'],
                    'date': parent.$route.query['date'],
                    'trend': parent.$route.query['trend'],
                   };
    } else {
        newquery =  parent.$route.query;
    }

    newquery[name] = value;
    console.log("SET URL: " + name + " = " + value + " on " + parent.$route.path);
    console.log("new query:");
    console.log(newquery);
    var newpath = "" + parent.$route.path;
    // this is such an ugly hack to get around vue failing to update parameters
    parent.$router.push({path: "/"})
        .then(() =>
            parent.$router.push({ 'path': newpath,
                                  'query': newquery}));
    //.catch(() => {});
}

export function epoch_to_date_str(epoch) {
    var date = new Date(1000 * epoch);
    return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate()
}

export function epoch_to_time_str(epoch) {
    var date = new Date(1000 * epoch);
    return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() +
        " " + date.getHours() + ":" + date.getMinutes()
}
