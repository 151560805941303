<template>
    <span>
        <v-checkbox 
            hide-details
            class="mt-0 mb-0 mr-0 ml-0 shrink"
            v-model="only_new_items"
            label="Only show new items">
        </v-checkbox>
        <v-simple-table dense
                        class="mt-2"
                        :key="change_counter">
        <tbody>
            <tr><th class="text-left">Rank</th>
                <th>{{trend_title}}</th>
                <th v-for="(obj,id) in supplimental_columns" :key="`col-${id}`">
                    {{supplimental_columns[id]['name']}}
                </th>
<!-- <th>Service</th> -->
                <th>Percent</th><th>Trend</th>
                <th>
                </th>
            </tr>
            <tr v-for="(data, index) in $data.filtered[trend_identifier][trend_type]"
                :key="`${data[0]}-${data[8]}-${trend_identifier}-${trend_type}-${date}`">
                <td>
                    <v-checkbox 
                        hide-details
                        class="mt-0 mb-0 mr-0 ml-0 shrink"
                        v-model="our_rows_checked"
                        :value="data[0]"
                        :label="'' + index"
                        @click="emit_clicks(data[0])">
                    </v-checkbox></td>
                <td>{{data[1]}}</td>
                <td v-for="(obj,id) in supplimental_columns" :key="`data-${id}`">
                    {{supplimental_columns[id]['values'][data[1]]}}
                </td>
                <td>{{data[6]}}</td>
                <td v-if="data[8] > 0">
                    <v-icon color="green">mdi-arrow-up-thick</v-icon>
                    {{data[8]}}
                </td>
                <td v-if="data[8] == 0">
                    <v-icon>mdi-arrow-right-thick </v-icon>
                </td>
                <td v-if="data[8] < 0">
                    <v-icon color="red">mdi-arrow-down-thick</v-icon>
                    {{-data[8]}}
                </td>
                <td>
                    <v-chip
                        v-if="data[9]"
                        x-small
                        color="red"
                        text-color="white">NEW</v-chip>
                    <v-chip
                        v-if="data[10]"
                        x-small
                        color="blue"
                        text-color="white">NOVEL</v-chip>
                </td>
            </tr>
        </tbody>
    </v-simple-table>
    <hr />
    </span>
</template>
<script>
 import { fetch_one } from "../utils.js";
 var ourdata = {
     our_rows_checked: [],
     supplimental_columns: [
         {'name': '',
          'values': {},}
     ],
     'only_new_items': false,
     'filtered': {'bogus':
                  {'trend': [],
                   'top': [],
                   'site': []}},
     'change_counter': 0,
 };

 var emit_clicks = function(item) {
     this.$emit('emit_clicks', {
         'new_value': item,
         'table': this.trend_identifier,
         'data': ourdata['our_rows_checked']
     });
 }

 var fetch_additional_columns = async function(trend_identifier) {
     try {
         var path = "/data/supplimental/" + trend_identifier + ".json";
         var results = await fetch_one(path);
         console.log("fetching supplimental: " + path);
         console.log(results);
         if (results &&
             Object.prototype.toString.call(results) === '[object Array]') {
             ourdata['supplimental_columns'] = results;
         } else {
             ourdata['supplimental_columns'] = [];
         }
     } catch (error) {
         ourdata['supplimental_columns'] = [];
     }
 }

 var filtered_rows = function(data, only_new) {
     var retarray = [];
     if (!data) {
         return [];
     }
     for (var i = 0; i < data.length; i++) {
         var is_new = data[i][9];
         if (!only_new || is_new) {
             retarray.push(data[i]);
         }
     }
     ourdata['change_counter']++;
     return retarray;
 }

 export default {
     name: 'TrendTable',
     props: ['trend_table', 'rows_checked', 'trend_title', 'trend_identifier', 'trend_type', 'date'],
     data: function() { return ourdata; },
     methods: {
         'emit_clicks': emit_clicks,
     },
     created() {
         ourdata['our_rows_checked'] = this.$props.rows_checked;
         fetch_additional_columns(this.$props.trend_identifier);
         ourdata['filtered'][this.$props.trend_identifier] = {};
         ourdata['filtered'][this.$props.trend_identifier][this.$props.trend_type] =
             filtered_rows(this.$props.trend_table, ourdata['only_new_items']);
     },
     watch: {
         'rows_checked': function(newval) {
             ourdata['our_rows_checked'] = newval;
         },
         'trend_identifier': function(newval) {
             fetch_additional_columns(newval);
         },
         'only_new_items': function() {
             if (!(this.$props.trend_identifier in ourdata['filtered'])) {
                 ourdata['filtered'][this.$props.trend_identifier] = {}
             }
             ourdata['filtered'][this.$props.trend_identifier][this.$props.trend_type] =
                 filtered_rows(this.$props.trend_table, ourdata['only_new_items']);
         },
         'trend_table': function() {
             console.log("TREND_TABLE CHANGED: " + this.$props.trend_identifier + "/" + this.$props.trend_type);
             if (!(this.$props.trend_identifier in ourdata['filtered'])) {
                 ourdata['filtered'][this.$props.trend_identifier] = {}
             }
             ourdata['filtered'][this.$props.trend_identifier][this.$props.trend_type] =
                 filtered_rows(this.$props.trend_table, ourdata['only_new_items']);
             // console.log(ourdata['filtered'][this.$props.trend_identifier][this.$props.trend_type]);
         },
     }
 };
</script>

