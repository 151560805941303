import Vue from 'vue'
import App from './App.vue'
import MalwareView from './views/MalwareView.vue'
import DailyBriefing from './views/DailyBriefing.vue'
import Explore from './views/Explore.vue'
import Gift from './views/Gift.vue'
import Annotations from './components/Annotations.vue'
//import Redirect from './views/Redirect.vue'
import vuetify from './plugins/vuetify';

import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader


import Router from 'vue-router';

Vue.use(Router);

Vue.config.productionTip = false

const routes = [
    {path: '/GiFT', name: 'GiFT', component: Gift},
    {path: '/Malware', name: 'MalwareView', component: MalwareView},
    {path: '/dailybriefing', name: 'DailyBriefing', component: DailyBriefing},
    {path: '/explore', name: 'Explore', component: Explore},
    {path: '/analysis', name: 'Analysis', component: Annotations},
    {path: '*', name: 'top', component: Gift},
]

const router = new Router({
    routes,
});

new Vue({
    vuetify,
    router,
  render: h => h(App)
}).$mount('#app')

// export default new Vuetify({
//   icons: {
//     iconfont: 'md',
//   },
// })
