<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-card-title>Create an annotation</v-card-title>
                    <v-card-text>
                        <v-form ref="annotate">
                            <p><strong>For URL: </strong>{{url}}</p>
                            <v-text-field
                                v-model="title"
                                label="Title">
                            </v-text-field>
                            <p><strong>Markdown Annotation:</strong></p>
                            <Editor mode="preview"
                                    rows="10"
                                    id="annotateedit"
                                    :emoji="false"
                                    :image="false"
                                    v-model="annotation_text"></Editor>
                            <v-btn color="primary"
                                   @click="submit_annotation()"
                                   small>Submit</v-btn>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
 import { Editor } from "vuetify-markdown-editor";
 var annotation_data = {
     title: "",
     annotation_text: "",
     url: "",
 }

 export default {
     name: 'Annotate',
     
     components: {
         Editor,
     },

     methods: {
         submit_annotation: async function() {
             console.log("annotation submittion");
             console.log("title: " + annotation_data['title']);
             console.log("text: " + annotation_data['annotation_text']);

             var url = "/#" + this.$route.fullPath;

             var post_data = {
                 'title': annotation_data['title'],
                 'text': annotation_data['annotation_text'],
                 'url': url,
             }

             // post data
             var settings = {
                 method: 'post',
                 headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json',
                 },
                 body: JSON.stringify(post_data),
             }
             var response = await fetch('/api/annotate/submit', settings);
             response = await response.json();

             // reset form
             annotation_data["title"] = "";
             annotation_data["annotation_text"] = "";
             this.$emit('done_annotating', response);
         }
     },
     created() {
         annotation_data['url'] = "/#" + this.$route.fullPath;
     },
     data() {
         return annotation_data;
     }
 };
</script>
<style scoped>
 #annotateedit {
     color: #ff0000;
     resize: both;
     overflow: auto;
 } 
</style>
