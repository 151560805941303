<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                <p class="mb-2">
                    The following analysis writeups highlight recent
                    findings in the GiFT datasets and analysis.  To
                    receive these as E-Mail digests when they come
                    out, please sign up for our <a href="https://mailman.isi.edu/mailman/listinfo/daily-security-briefings">daily-security-briefings mailing list</a> or by subscribing to our <a href="/">MISP Feeds</a>.
                </p>
                <v-card
                    class="mb-2"
                    v-for="(annotation, index) in annotations"
                    :key="`annotation-${index}`">
                    <v-card-title>
                        {{annotation[3]}}
                    </v-card-title>
                    <v-card-text>
                        <v-simple-table width="100%" class="mb-1">
                            <tr><th class="text-left" width="10%">Date:</th>
                                <td>{{convert_date(annotation[1])}}</td></tr>
                            <tr><th class="text-left" width="10%">Author:</th>
                                <td>{{annotation[0]}}</td></tr>
                            <tr><th class="text-left" width="10%">About:</th>
                                <td>
                                    <a :href="annotation[2]">
                                        {{annotation[2]}}
                                    </a>
                                </td></tr>
                            <tr><th class="text-left" >Analysis:</th><td></td></tr>
                        </v-simple-table>
                        <Editor rows="20" mode="viewer" v-model="annotation[4]"></Editor>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
 import { Editor } from "vuetify-markdown-editor";
 var annotation_data = {
     'annotations': {}
 };
 import { fetch_one } from "../utils.js";

 var fetch_annotations = async function() {
     var data = await fetch_one("/api/annotate/view", 'annotations');
     console.log(data);
     annotation_data['annotations'] = data['message'];
 }

 export default {
     name: 'Annotations',
     components: {
         Editor,
     },
     data: function() { return annotation_data; },
     props: [],
     created() {
         fetch_annotations();
     },
     methods: {
         convert_date(value) {
             var date = new Date(value * 1000);
             return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate() +
                    " " + date.getHours() + ":" + date.getMinutes()
         }
     }
 };
</script>
